import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operation',
  standalone: true
})
export class OperationPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    return {
      0: 'Enable',
      1: 'Pause',
      2: 'Cpa',
      3: 'Bid',
      4: 'None',
      5: 'Budget',
      6: 'Max Conversions'
    }[value] ?? 'None';
  }

}
