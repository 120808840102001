<label *ngIf="hasButtons && label !== undefined" class="mv-input-label" [ngClass]="{'mb-1': (description === ''), '-mb-1': (description !== '') }" for="name">{{ label }}</label>
<div class="mv-input-wrapper" [class.mv-input-wrapper-buttons]="hasButtons" [ngClass]="{'gm-input-error': hasErrors }">
  <label *ngIf="!hasButtons && label !== undefined" class="mv-input-label" [ngClass]="{'mb-1': (description === ''), '-mb-1': (description !== '') }" for="name">{{ label }}</label>
  <small class="-mt-3 text-black-lighter" *ngIf="description !== ''">{{ description }}</small>

  <div class="flex justify-between">
    <div class="mv-input-left" #buttonLeft>
      <ng-content select="[button-left]"></ng-content>
    </div>

    <div class="w-full">
      <ng-content></ng-content>
    </div>

    <div class="mv-input-right" #button>
      <ng-content select="[button]"></ng-content>
    </div>
  </div>
</div>

