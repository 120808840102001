<p-dialog #dialog (onShow)="load()" [visible]="open" (visibleChange)="toggle($event)" draggable="false" [modal]="true">

    <ng-template pTemplate="header">
        <div class="flex">
            <mv-title [size]="3">Comments</mv-title>
        </div>
    </ng-template>

    <div class="flex items-center -mt-3">
        <input mvInput [(ngModel)]="text" placeholder="Send comment ..." name="comment" class="w-full mr-2" type="text">

        <mv-button icon="send" (click)="send()"></mv-button>
    </div>    

    <ng-container *ngIf="comments.length > 0">
        <div *ngFor="let comment of comments" class="ga-comment group mt-3">
            <div class="ga-comment__author-holder">
                <div>{{ comment.created_at | date: 'd MMM - HH:mm'}}</div>
                <div class="ga-comment__author">{{ comment.author.name }}</div>
            </div>
            <div class="ga-comment__holder">
                <div class="ga-comment__text">
                    {{ comment.text }}
                </div>
                <div class="ga-comment__delete-button invisible group-hover:visible">
                    <mv-button
                        icon="delete" 
                        variant="danger-transparent" 
                        (click)="delete(comment.id)">
                    </mv-button>
                </div>
            </div>
        </div>
    </ng-container>

</p-dialog>