import { Pipe, PipeTransform } from '@angular/core';
import { IStatusChangeOperation, IStatusChangeType } from '../interfaces/status-change';
import { CurrencyPipe, PercentPipe } from '@angular/common';

@Pipe({
  name: 'statusValue',
  standalone: true
})
export class StatusValuePipe implements PipeTransform {

  transform(value: string, operation: number, currency: string): any {
    if (operation === undefined || value === undefined) {
      return 'None'
    }

    if ([IStatusChangeOperation.BUDGET, IStatusChangeOperation.CPA].includes(operation)) {
      return new CurrencyPipe('en_US', 'USD').transform(value, currency)
    }

    if (IStatusChangeOperation.BID === operation) {
      return new PercentPipe("en_US").transform(value)
    }

    if ([IStatusChangeOperation.MAXIMIZE_CONVERSIONS].includes(operation)) {
      return value == '1' ? 'Enabled' : 'Disabled'
    }

    if ([IStatusChangeOperation.ENABLE, IStatusChangeOperation.PAUSE].includes(operation)) {
      return {
        0: 'Paused',
        1: 'Enabled',
        2: 'Enabled',
        3: 'Paused',
      } [value] ?? 'Invalid'
    }



    return value;
  }

}