import { Account, IAccount } from "src/app/account/interfaces/account";

export interface GraphValueParser {

  parse(value: any): any;
  key(): string;

}


export class NoopGraphValueParser {

  parse(value: any) {
    return value;
  }

  key() {
    return 'none';
  }

}

export class FillGraphValueParser implements GraphValueParser {

  parse(value: any) {
    return value.percentage - 100;
  }

  key(): string {
    return "fill";
  }

}


export class AdjustedRoiGraphValueParser implements GraphValueParser{

  maxCost: number;

  constructor(maxCost: number) {
    this.maxCost = maxCost;
  }

  parse(value: any) {
    return (value.roi * this.maxCost) / 100;
  }

  reverse(value: any) {
    return (value / this.maxCost) * 100;
  }

  key(): string {
    return "adjustedRoi";
  }

}

export class RoiGraphValueParser implements GraphValueParser {

  parse(value: any) {
    return this.adapt(value.roi);
  }

  adapt(value: any) {
    if (value < 20) {
      return 20;
    }

    if (value > 100) {
      return 100;
    }

    return value;
  }

  key() {
    return "roi";
  }

}

export class CostGraphValueParser implements GraphValueParser {

  parse(value: any) {
    return value.cost;
  }

  key() {
    return "cost";
  }

}

export class ProfitGraphValueParser implements GraphValueParser {

  parse(value: any) {
    return value / 100;
  }

  key() {
    return "profit";
  }

}

export class AccountCpmValueParser implements GraphValueParser {
  account: IAccount;
  maxCost: number;

  constructor(account: IAccount, maxCost: number) {
    this.account = account;
    this.maxCost = maxCost;
  }

  parse(value: any) {
    return Math.round((((value.cost / 1000000) / (value.impressions / 1000)))) * (this.maxCost / 100)
  }

  reverse(value: any) {
    return value / (this.maxCost / 100)
  }

  currency(): string {
    return Account.getCurrencyCode(this.account);
  }

  key(): string {
    return 'cpm'
  }

}

export class AdCtrValueParser implements GraphValueParser {
  maxCost: number;

  constructor(maxCost: number) {
    this.maxCost = maxCost;
  }

  parse(value: any) {
    return Math.round((value.clicks / (value.impressions / 1000)) * (this.maxCost / 100))
  }

  reverse(value: any) {
    return (value / (this.maxCost / 100));
  }

  key(): string {
    return 'adctr'
  }
}

export class LanderCtrValueParser implements GraphValueParser {
  maxCost: number;

  constructor(maxCost: number) {
    this.maxCost = maxCost;
  }

  parse(value: any) {
    return Math.round((value.clicksTracked / value.viewsTracked) * this.maxCost)
  }

  reverse(value: any) {
    return (value / this.maxCost) * 100;
  }

  key(): string {
    return 'lctr'
  }
}

export class CVRValueParser implements GraphValueParser {
  maxCost: number;

  constructor(maxCost: number) {
    this.maxCost = maxCost;
  }

  parse(value: any) {
    return Math.round((value.conversionsTracked / value.clicksTracked) * this.maxCost)
  }

  reverse(value: any) {
    return (value / this.maxCost) * 100;
  }

  key(): string {
    return 'cvr'
  }
}


