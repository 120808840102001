import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeLabel',
  standalone: true
})
export class TypeLabelPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    return {
      0: 'Campaign',
      1: 'Ad',
      2: 'Demographics',
      3: 'Demographics',
      4: 'Demographics',
      5: 'Demographics',
      6: 'Demographics',
      7: 'Campaign',
      8: 'Campaign',
      9: 'Campaign',
    }[value] ?? 'No type';
  }

}
