import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { Observable, of } from 'rxjs';
import { IVersion } from '../interfaces/version';

/**
 * @deprecated There is a new thing
 */
@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(private backend: BackendService) { }

  public getVersion(): Observable<IVersion> {
    return of({version: '3.8'})
  }

}
