<ga-feature feature="account_statistics">
  <div default>
    <mv-card [table]="true" *ngIf="v4changes" (mouseleave)="unhighlight()">
      <div header>
        <div class="-m-5"></div>
      </div>
      <div body>
        <p-table 
          [value]="v4changes" 
          [scrollable]="true"
          scrollHeight="220px"
          styleClass="p-datatable-striped p-datatable-small">
          <ng-template pTemplate="header">
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Type</th>
              <th>Operation</th>
              <th>Value</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-change>
            <tr (mouseenter)="highlightCampaign(change.campaign_id)">
              <td>
                <div class="flex items-center text-xs font-bold text-black">
                  <div class="h-2 w-2 rounded-full bg-primary mr-3" *ngIf="sameDay(change.created_at, today)"></div>
                  <div>{{ change.created_at | date: 'd MMM - HH:mm' : 'Europe/Brussels' }}</div>
                </div>
              </td>
              <td><span class="text-xs font-bold text-black">{{ change.author.name }}</span></td>
              <td><span [pTooltip]="change.description" class="text-xs text-secondary underline">{{ change.type | typeLabel }}</span></td>
              <td><span class="text-xs font-bold text-black">{{ change.operation | operation }}</span></td>
              <td>
                <span class="text-xs font-bold text-secondary">
                  {{ change.old_value | statusValue: change.operation:change.account.currency }} → <span class="font-bold text-primary">{{ change.value | statusValue: change.operation:change.account.currency }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="15" class="mv-table-empty">
                    <i class="material-symbols-outlined mv-table-empty__icon">delete_history</i>
                    <div class="mv-table-empty__label">No Change History</div>
                </td>
            </tr>
        </ng-template>
        </p-table>
      </div>
    </mv-card>
  </div>

  <div fallback>
    <mave-loader [isLoading]="isLoadingChanges" [isSmall]="true">
      <div class="mt-5 max-h-56 overflow-y-auto">
        <mave-card [border]="false" [header]="false" [footer]="false" class="">
          <mave-datatable [empty]="statusHeaders && statusHeaders.length === 0" [headers]="statusHeaders" class="block overflow-x-auto">
            <tr *ngFor="let change of changes" maveDataTableRow>
              <td class="font-mono font-semibold text-sm text-secondary p-1.5 pl-3"><span class="text-black text-xs">{{change.createdAt | date : 'd MMM - HH:mm' : 'Europe/Brussels' }}</span></td>
              <td class="font-mono pl-2 font-semibold text-sm text-secondary p-1.5 pl-3.5"><span class="text-black text-xs">{{change.authorName}}</span></td>
              <td class="font-mono font-semibold text-sm text-secondary p-1.5 pl-3.5" *ngIf="change.type !== 'Campaign'"><span class="text-black text-xs">{{change.type}}</span></td>
              <td class="font-mono font-semibold text-sm text-secondary p-1.5 pl-3.5" *ngIf="change.type === 'Campaign'">
                <a [href]="buildCampaignLink(accountId, change.campaignId)" [routerLink]="buildCampaignLink(accountId, change.campaignId)" target="_blank" rel="noopener noreferrer"
                   pTooltip="{{change.campaignName}}" tooltipPosition="top"
                   class="text-black text-xs underline cursor-pointer">{{change.type}}</a>
              </td>
              <td class="font-mono font-semibold text-sm text-secondary p-1.5 pl-3.5"><span class="text-black text-xs font-bold">{{change.operation}}</span></td>
              <td class="font-mono font-semibold text-sm text-secondary p-1.5 pl-3.5">
                <span class="text-primary-light text-xs" *ngIf="(change.type | statusType) === 'Demographics'">{{ change.type }}: </span>
                <span class="text-primary-light text-xs" *ngIf="change.type !== 'Campaign'">{{ change.name }}</span>
                <span class="text-primary-light text-xs" *ngIf="(change.type | statusType) !== 'Demographics' && (change.type | statusType) !== 'Campaign' && (change.type | statusType) !== 'Ad' || (change.type === 'Cpa')">: </span>
                <span *ngIf="(change.operation | operationIsValue)"><span class="text-secondary text-xs"> {{ change.oldValueFormatted }}</span><span class="font-bold"> → </span><span class="text-primary-light text-xs">{{ change.valueFormatted }}</span></span>
    
                <span class="text-primary-light text-xs" *ngIf="change.operation  === 'MAXIMIZE_CONVERSIONS'"> Maximize Conversions </span>
    
              </td>
            </tr>
          </mave-datatable>
    
          <div class="text-center mx-auto" *ngIf="(changes === undefined || changes.length === 0)">
            <div class="font-bold text-primary-light text-lg py-4">No Changes</div>
          </div>
        </mave-card>
      </div>
    </mave-loader>
  </div>
</ga-feature>






