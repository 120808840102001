export class Calculator {

  calculation: Calculation;
  currentCpa: number;
  currentBid: number;

  constructor(bid: number, costConv: number) {
    this.calculation = Calculation.noop();
    this.currentCpa = costConv;
    this.currentBid = bid;
  }

  fromRelativePercentage(relativePercentage: number): Calculation {
    const projectedCpa = this.currentCpa * (1 + (relativePercentage / 100));
    const bidAdjustment = (-1 + ((1 + (this.currentBid / 100)) * (1 + (relativePercentage / 100)))) * 100


    return new Calculation(relativePercentage, bidAdjustment, projectedCpa);
  }

  fromBidAdjustment(bidAdjustment: number): Calculation {
    const projectedCpa = (this.currentCpa * ((1 + bidAdjustment / 100) / (1 + (this.currentBid / 100))));
    const relativePercentage = (-1 + (((1 + (bidAdjustment / 100)) / (1 + (this.currentBid / 100))))) * 100

    return new Calculation(relativePercentage, bidAdjustment, projectedCpa);
  }

  fromProjectedCpa(projectedCpa: number): Calculation {

    const relativePercentage = (-1 + (projectedCpa / this.currentCpa)) * 100
    const bidAdjustment = (-1 + ((1 + (this.currentBid / 100)) * (projectedCpa / this.currentCpa))) * 100


    return new Calculation(relativePercentage, bidAdjustment, projectedCpa);
  }
}

export class Calculation {
  relativePercentage = 0;
  bidAdjustment = 0;
  projectedCpa = 0;

  constructor(relativePercentage: number, bidAdjustment: number, projectedCpa: number) {
    this.relativePercentage = relativePercentage;
    this.bidAdjustment = bidAdjustment;
    this.projectedCpa = projectedCpa;
  }

  static noop(): Calculation {
    return new Calculation(0, 0, 0);
  }

  getRelativePercentage(): number {
    return this.relativePercentage;
  }

  setRelativePercentage(relativePercentage: number): void {
    this.relativePercentage = relativePercentage;
  }

  getBidAdjustment(): number {
    return this.bidAdjustment;
  }

  setBidAdjustment(bid: number): void {
    this.bidAdjustment = bid;
  }

  getProjectedCpa(): number {
    return this.projectedCpa;
  }

  getProjectedCpaFloat(): number {
    return this.projectedCpa
  }

  setProjectedCpa(cpa: number): void {
    this.projectedCpa = cpa;
  }

  getFormattedRelativePercentage() {
    return this.relativePercentage.toFixed(2);
  }

  getFormattedBidAdjustment() {
    return this.bidAdjustment.toFixed(2);
  }

  getFormattedProjectedCpa() {
    return this.projectedCpa.toFixed(2);
  }

}
