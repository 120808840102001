
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRange } from 'src/app/lib/classes/daterange';
import { IAccount } from '../interfaces/account';
import { Customer } from '../views/legacy/create/create.component';
import { BackendService } from 'src/app/lib/backend.service';
import { DataResponse } from 'src/app/mavejs/http/interfaces/data-response';

/**
 * @deprecated Use Accountv4Service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private backend: BackendService) {}

  getSingleAccount(accountId: number) {
    return this.backend.getSingle("/api/v1/account/" + accountId);
  }

  getAccounts(sortBy: string): Observable<IAccount[]> {
    return this.backend.getAll("/api/v1/account?sort=" + sortBy)
  }

  getAccountMetrics(range: DateRange, page: number, sortBy: string, direction: string) {
    const from = range.getFrom();
    const to = range.getTo();

    return this.backend.getAll(
      "/api/v1/account/with_metrics/" +
      from.getFullYear() + "/" + (from.getMonth() + 1) + "/" + from.getDate() + "/to/" +
      to.getFullYear() + "/" + (to.getMonth() + 1) + "/" + to.getDate() +
      "?page=" + page +
      "&sort=" + sortBy +
      "&order=" + direction
    )
  }

  getAccountStatistics(range: DateRange) {
    const from = range.getFrom();
    const to = range.getTo();

    return this.backend.getAll(
      "/api/v1/account/statistics/" +
      from.getFullYear() + "/" + (from.getMonth() + 1) + "/" + from.getDate() + "/to/" +
      to.getFullYear() + "/" + (to.getMonth() + 1) + "/" + to.getDate()
    )
  }

  getCustomers(mccId: string, code: string): Observable<DataResponse> {
    return this.backend.getAll("/api/v1/account/mcc/list?mccId=" + mccId + "&code=" + code)
  }

  storeCustomers(mccId: string, code: string, token: string, customers: Array<Customer>) {
    return this.backend.sendPost("/api/v1/account/create/multiple?mccId=" + mccId + "&code=" + code + "&token=" + token, customers);
  }

  refreshCustomers(mccId: string, code: string) {
    return this.backend.sendPost("/api/v1/account/refresh_token", {code: code, mccId: mccId});
  }

  getRefreshCustomersDropdown(): Observable<DataResponse> {
    return this.backend.getAll("/api/v1/account/mcc/dropdown");
  }

  getRefreshAuthorizationUrl(): Observable<DataResponse> {
    return this.backend.getSingle("/api/v1/account/authorization_code?endpoint=/account/refresh")
  }

  getAuthorizationUrl(): Observable<DataResponse> {
    return this.backend.getSingle("/api/v1/account/authorization_code?endpoint=/account/create")
  }

  deleteAccount(account: IAccount): Observable<IAccount> {
    return this.backend.sendDelete("/api/v1/account/" + account.id)
  }

  updateAccount(account: IAccount): Observable<IAccount> {
    return this.backend.sendPut("/api/v1/account/save", account)
  }

  updateAccountName(accountId: number, newAccountName: string): Observable<IAccount> {
    return this.backend.sendPut("/api/v1/account/" + accountId + "/save_name", newAccountName)
  }

  refresh(account: IAccount): Observable<DataResponse> {
    return this.backend.sendPost("/api/v1/account/" + account.id + "/refresh", {})
  }

  status(): Observable<DataResponse> {
    return this.backend.sendPost("/api/v1/account/status", {})
  }

  refreshDevices(account: IAccount): Observable<DataResponse> {
    return this.backend.sendPost("/api/v1/device/sync/" + account.id, {});
  }

  refreshAges(account: IAccount): Observable<DataResponse> {
    return this.backend.sendPost("/api/v1/age/sync/" + account.id, {});
  }

  refreshGenders(account: IAccount): Observable<DataResponse> {
    return this.backend.sendPost("/api/v1/gender/sync/" + account.id, {});
  }

  refreshParentals(account: IAccount): Observable<DataResponse> {
    return this.backend.sendPost("/api/v1/parental/sync/" + account.id, {});
  }

  refreshIncomes(account: IAccount): Observable<DataResponse> {
    return this.backend.sendPost("/api/v1/income/sync/" + account.id, {});
  }

  getAccountGraph(range: DateRange) {
    const from = range.getFrom();
    const to = range.getTo();

    return this.backend.getAll(
      "/api/v1/account/statistics/graph/" +
      from.getFullYear() + "/" + (from.getMonth() + 1) + "/" + from.getDate() + "/to/" +
      to.getFullYear() + "/" + (to.getMonth() + 1) + "/" + to.getDate()
    )
  }

}
