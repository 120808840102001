import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { ButtonComponent } from '../../../mavejs/form/button/button.component';
import { TextboxComponent } from '../../../mavejs/form/textbox/textbox.component';

@Component({
    selector: 'ganal-alert-form',
    templateUrl: './alert-form.component.html',
    styleUrls: ['./alert-form.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TextboxComponent, ButtonComponent]
})
export class AlertFormComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder, private alertService: AlertService) { }

  alertForm!: UntypedFormGroup;
  isLoadingAlertSend = false;
  isLoadingAlertClear = false;

  ngOnInit(): void {
    this.alertForm = this.fb.group({
      message: ['', Validators.required]
    })
  }

  get message() {
    return this.alertForm.get('message');
  }

  submitAlert() {
    this.isLoadingAlertSend = true;
    this.alertService.createAlert({ message: this.message?.value }).subscribe(() => {
      this.isLoadingAlertSend = false;
    })
  }

  clearAlert() {
    this.isLoadingAlertClear = true;
    this.alertService.clearAlert().subscribe(() => {
      this.isLoadingAlertClear = false;
    });
  }

}
