
export function featureSet(key: string, isEnabled: boolean) {
    localStorage.setItem('v4feature_' + key, isEnabled ? 'true' : 'false')
}

export function featureIsActivated(key: string) {
    return localStorage.getItem('v4feature_' + key) === 'true';
}


