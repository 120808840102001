import { Component, Input, ViewChild } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { Syncv4Service } from 'src/app/sync/services/syncv4.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'ga-sync',
  standalone: true,
  imports: [
    FormsModule,
    ButtonComponent,
    OverlayPanelModule,
    CalendarModule
  ],
  templateUrl: './sync.component.html',
  styleUrl: './sync.component.css'
})
export class SyncComponent {

  @ViewChild('syncOverlay') syncOverlay!: OverlayPanel;

  @Input() accountId!: number;
  @Input() type!: string;

  date!: Date
  isSyncing: boolean = false;

  /**
   * 
   * @param syncv4 
   */
  constructor(private syncv4: Syncv4Service) {}

  /**
   * 
   * @param $event 
   */
  sync($event: Event) {
    this.isSyncing = true
    this.syncv4.sync(this.accountId, this.type, this.date).subscribe(() => {
      this.syncOverlay.toggle($event)
      this.isSyncing = false;
    })
  }

}
