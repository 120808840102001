import { Component, Input, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'ganal-navbar-item',
    templateUrl: './navbar-item.component.html',
    styleUrls: ['./navbar-item.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive]
})
export class NavbarItemComponent implements OnInit {
  @Input() link!: string;
  @Input() label: string = 'Link'

  constructor() { }

  ngOnInit(): void {
  }

}
