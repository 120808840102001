<div class="flex">
    <mv-card class="ad-totals__sidepane mr-4">
        <div header>
            <mv-title [size]="3">Range Selector</mv-title>   
        </div>
        <div body>
            <div class="ad-totals__range-card">
                <ng-content></ng-content>
            </div>
        </div>
    </mv-card>
    <mv-card class="w-full" [hasHeader]="false">
        <div body>
            <div class="flex justify-between">
                <ng-container *ngFor="let range of ranges">
                    <div *ngIf="range">
                        <div class="ad-totals__range">
                            {{ range | rangeLabel }}
                        </div>
                        <div class="ad-totals__grid">
                            <div>
                                <div class="ad-totals__number">{{ (rangeTotals.get(range.toString())?.total_cost |
                                    currency: account.currency:undefined:'1.0-1') ?? '-' }}</div>
                                <div class="ad-totals__label">Cost</div>
                            </div>
                            <div>
                                <div class="ad-totals__number ga-totals-green">{{ (rangeTotals.get(range.toString())?.total_revenue |
                                    currency: account.currency:undefined:'1.0-1') ?? '-' }}</div>
                                <div class="ad-totals__label ga-totals-green-light">Revenue</div>
                            </div>
                            <div>
                                <div class="ad-totals__number">{{ (((rangeTotals.get(range.toString())?.total_profit ??
                                    0) / (rangeTotals.get(range.toString())?.total_cost ?? 0)) | percent: '1.0') ?? '-%'
                                    }}</div>
                                <div class="ad-totals__label">ROI</div>
                            </div>
                            <div>
                                <div class="ad-totals__number ga-totals-yellow">{{
                                    (((rangeTotals.get(range.toString())?.total_cost ?? 0) /
                                    (rangeTotals.get(range.toString())?.total_impressions ?? 0)) * 1000 | currency:
                                    account.currency:undefined:'1.0-1') ?? '-' }}</div>
                                <div class="ad-totals__label ga-totals-yellow-light ga-totals-red-light">CPM</div>
                            </div>
                            <div>
                                <div class="ad-totals__number ga-totals-red">{{ (((rangeTotals.get(range.toString())?.total_clicks ?? 0) / (rangeTotals.get(range.toString())?.total_impressions ?? 0)) | percent: '1.2') ?? '-%' }}</div>
                                <div class="ad-totals__label ga-totals-red-light">Ad CTR</div>
                            </div>
                            <div>
                                <div class="ad-totals__number ga-totals-blue">{{
                                    rangeTotals.get(range.toString())?.total_conversions_tracked ?? '-' }}</div>
                                <div class="ad-totals__label ga-totals-blue-light">Conv</div>
                            </div>
                       
                            <!-- <div>
                                <div class="ad-totals__number ga-totals-yellow">{{
                                    (((rangeTotals.get(range.toString())?.total_cost ?? 0) /
                                    (rangeTotals.get(range.toString())?.total_conversions_tracked ?? 0)) | currency:
                                    account.currency:undefined:'1.0-1') ?? '-' }}</div>
                                <div class="ad-totals__label ga-totals-yellow-light">Cost/Conv</div>
                            </div> -->

                            <div>
                                <div class="ad-totals__number">{{
                                    (((rangeTotals.get(range.toString())?.total_clicks_tracked ?? 0) /
                                    (rangeTotals.get(range.toString())?.total_views_tracked ?? 0)) | percent: '1.2')
                                    ?? '-' }}</div>
                                <div class="ad-totals__label">LCTR</div>
                            </div>
                            <div>
                                <div class="ad-totals__number">{{ (((rangeTotals.get(range.toString())?.total_conversions_tracked ?? 0) / (rangeTotals.get(range.toString())?.total_clicks_tracked ?? 0)) | percent) ?? '-%' }}</div>
                                <div class="ad-totals__label">CVR</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

    </mv-card>
</div>