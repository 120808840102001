import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { featureIsActivated } from '../lib/classes/feature';

@Component({
  selector: 'ga-feature',
  standalone: true,
  imports: [NgIf],
  templateUrl: './feature.component.html',
  styleUrl: './feature.component.css'
})
export class FeatureComponent {
  @Input() feature!: string
  
  get isActivated() {
    return featureIsActivated(this.feature)
  }

}
