import { CurrencyPipe, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SkeletonModule } from 'primeng/skeleton';
import { IAccount } from 'src/app/account/interfaces/account';
import { BudgetDialogComponent } from 'src/app/campaign/components/budget-dialog/budget-dialog.component';
import { CpaDialogComponent } from 'src/app/campaign/components/cpa-dialog/cpa-dialog.component';
import { ICampaignDetail, ICampaignPagination, IMeanBudget } from 'src/app/campaign/interfaces/campaign';
import { Campaignv4Service } from 'src/app/campaign/services/campaignv4.service';
import { MondayComponent } from 'src/app/lib/components/monday/monday.component';
import { WorkbarComponent } from 'src/app/lib/components/workbar/workbar.component';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { IStatusChangeType, IStatusHistory, IStatusPayload } from 'src/app/status/interfaces/status-change';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';

@Component({
  selector: 'ga-ad-workbar',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    FormsModule,
    WorkbarComponent,
    InputSwitchModule,
    SkeletonModule,
    CpaDialogComponent,
    BudgetDialogComponent,
    CurrencyPipe,
    BadgeModule,
    ButtonComponent,
    RouterLink,
    CheckboxModule,
    MondayComponent,
  ],
  templateUrl: './ad-workbar.component.html',
  styleUrl: './ad-workbar.component.css',
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class AdWorkbarComponent implements OnInit {
  @Input() campaignId!: number;
  @Input() account!: IAccount;

  campaign!: ICampaignDetail
  campaignChanges: Array<IStatusHistory> = [];
  isCampaignChangesLoaded = false;
  isCampaignLoaded = false;

  budgetChanges: Array<IStatusHistory> = [];
  cpaChanges: Array<IStatusHistory> = [];
  maxConversionChanges: Array<IStatusHistory> = []

  cpaDialogIsOpen = false;
  newCpaValue = 200;

  budgetDialogIsOpen = false;
  newBudgetValue = 200;

  meanBudget!: IMeanBudget;
  paginator!: ICampaignPagination;
  isRunning: boolean = false;

  constructor(
    private campaignv4: Campaignv4Service, 
    private statusv4: Statusv4Service, 
    private message: MessageService,
    private router: Router
  ) {}


  ngOnInit(): void {
    this.campaignv4.getMeanBudget(this.campaignId).subscribe((mean: IMeanBudget) => {
      this.meanBudget = mean
    })

    this.campaignv4.getCampaign(this.campaignId).subscribe((campaign: ICampaignDetail) => {
      this.campaign = campaign;
      this.isCampaignLoaded = true;

      this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Campaign).subscribe((changes: Array<IStatusHistory>) => {
        this.campaignChanges = changes;
        this.isCampaignChangesLoaded = true;
      });

      this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Budget).subscribe((changes: Array<IStatusHistory>) => {
        this.budgetChanges = changes;
      });

      this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Cpa).subscribe((changes: Array<IStatusHistory>) => {
        this.cpaChanges = changes;
      });

      this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.MaxConversions).subscribe((changes: Array<IStatusHistory>) => {
        this.maxConversionChanges = changes;
      });

      this.campaignv4.paginate(this.account.id, this.campaignId, this.isRunning).subscribe((paginator: ICampaignPagination) => {
        this.paginator = paginator;
      })
    })
  }

  toggleIsRunning() {
    this.isRunning = !this.isRunning;
    this.campaignv4.paginate(this.account.id, this.campaignId, this.isRunning).subscribe((paginator: ICampaignPagination) => {
      this.paginator = paginator;
    })
  }


  hasStatus(campaign: ICampaignDetail) {
    if (campaign === undefined) {
      return false;
    }

    return this.campaignChanges.find(item => item.entity_id === campaign.id) !== undefined
  }

  changeStatus(campaign: ICampaignDetail) {
    let payload: IStatusPayload = {
      id: campaign.id,
      account_id: this.account.id,
      campaign_id: campaign.id,
      ad_group_id: 0,
      type: IStatusChangeType.Campaign,
    }
    
    setTimeout(() => {
      this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Campaign).subscribe((changes: Array<IStatusHistory>) => {
        this.campaignChanges = changes;
      })
    }, 100)

    this.statusv4.apply(payload).subscribe({
      complete: () => {},
      error: () => this.message.add({
        severity: 'error',
        summary: 'Could not change status'
      })
    })
  }

  openCpaDialog() {
    this.cpaDialogIsOpen = true;
    this.newCpaValue = parseInt(this.campaign.target_cpa)
  }


  changeCpa() {
    this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Cpa).subscribe((changes: Array<IStatusHistory>) => {
      this.cpaChanges = changes;
    })

    this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.MaxConversions).subscribe((changes: Array<IStatusHistory>) => {
      this.maxConversionChanges = changes;
    })
  }

  getCpaChange() {
    let change = this.cpaChanges.find(item => item.entity_id === this.campaign.id);

    if (change === undefined) {
      return 0;
    }

    return change.value
  }

  hasMaxConversionsChange() {
    let change = this.maxConversionChanges.find(item => item.entity_id === this.campaign.id);

    if (change === undefined) {
      return false;
    }

    return true
  }

  openBudgetDialog() {
    this.budgetDialogIsOpen = true;
    this.newBudgetValue = this.campaign.budget
  }

  changeBudget() {  
    this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Budget).subscribe((changes: Array<IStatusHistory>) => {
      this.budgetChanges = changes;
    })
  }

  getBudgetChange() {
    let change = this.budgetChanges.find(item => item.entity_id === this.campaign.id);

    if (change === undefined) {
      return 0;
    }

    return change.value
  }

  paginate(accountId: number, campaignId: number) {
    this.router.navigateByUrl('/ad/' + accountId + '/account/' + campaignId + '/adgroup').then(() => {
      location.reload();
    })
    
  }


}
