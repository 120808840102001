import { IGraphFormatter } from "./igraph-formatter";

export class GraphRoiFormatterBuilder implements IGraphFormatter {
  private value: any;

  constructor(value: any) {
    this.value = value.roi;
  }

  format(): string {
    return Math.round(this.value) + '%';
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }
}
