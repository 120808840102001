import { CommonModule, NgClass, NgIf } from '@angular/common';
import { Component, ContentChild, ElementRef, Input, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'mv-input',
  standalone: true,
  imports: [NgIf, NgClass, ReactiveFormsModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.css',
  encapsulation: ViewEncapsulation.None
})
export class InputComponent {
  @Input() label!: string;
  @Input() name!: string;
  @Input() description: string = '';
  @Input() control!: AbstractControl | null;

  @ViewChild("button") button!: ElementRef;
  @ViewChild("buttonLeft") buttonLeft!: ElementRef;

  hasButtons = false;
  hasLeftButtons = false;
  hasRightButtons = false;

  constructor() { }

  ngAfterContentChecked(): void {
    if (this.button !== undefined && this.button.nativeElement.children.length > 0) {
      this.hasRightButtons = true;
    }

    if (this.buttonLeft !== undefined && this.buttonLeft.nativeElement.children.length > 0) {
      this.hasLeftButtons = true;
    }

    this.hasButtons = (this.hasLeftButtons || this.hasRightButtons)
  }

  get hasErrors() {
    return this.control?.touched && this.control.errors;
  }

}
