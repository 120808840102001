import { Component } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { DaterangepickerComponent } from 'src/app/lib/ui/daterangepicker/daterangepicker.component';
import { InputComponent } from 'src/app/lib/ui/input/input.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RouterLink } from '@angular/router';
import { SkeletonModule } from 'primeng/skeleton';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { Campaign, ICampaign, ICampaignDetail, ICampaignMetrics } from '../../interfaces/campaign';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CampaignTypeIconPipe } from '../../pipes/campaign-type-icon.pipe';
import { CurrencyPipe, DatePipe, NgIf, PercentPipe } from '@angular/common';
import { YtGradingDirective } from 'src/app/lib/directives/ytgrading.directive';
import { BadgeModule } from 'primeng/badge';
import { ConversionDirective } from 'src/app/lib/directives/conversion.directive';
import { startOfToday, startOfYesterday, subWeeks } from 'date-fns';
import { DateRange } from 'src/app/lib/classes/daterange';
import { Campaignv4Service } from '../../services/campaignv4.service';
import { CpaDialogComponent } from '../../components/cpa-dialog/cpa-dialog.component';
import { BudgetDialogComponent } from '../../components/budget-dialog/budget-dialog.component';
import { IAccount } from 'src/app/account/interfaces/account';
import { UserService } from 'src/app/user/services/user.service';
import { debounceTime } from 'rxjs';
import { TotalsComponent } from 'src/app/lib/components/totals/totals.component';
import { ITotals } from 'src/app/lib/interfaces/statistics';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { IUser } from 'src/app/auth/classes/user';
import { IStatusChangeType, IStatusHistory, IStatusPayload } from 'src/app/status/interfaces/status-change';
import { Obfuscator } from 'src/app/lib/classes/obfuscator';

@Component({
  selector: 'ga-recent-campaigns-view',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    TitleComponent,
    DaterangepickerComponent,
    InputComponent,
    DropdownModule,
    ButtonComponent,
    OverlayPanelModule,
    TableModule,
    InputSwitchModule,
    SkeletonModule,
    RouterLink,
    CampaignTypeIconPipe,
    DatePipe,
    YtGradingDirective,
    CurrencyPipe,
    PercentPipe,
    BadgeModule,
    ConversionDirective,
    CpaDialogComponent,
    BudgetDialogComponent,
    TotalsComponent
  ],
  templateUrl: './recent-campaigns-view.component.html',
  styleUrl: './recent-campaigns-view.component.css',
  providers: [MessageService]
})
export class RecentCampaignsViewComponent {

  recentCampaigns!: Array<ICampaignMetrics>
  campaignChanges: Array<IStatusHistory> = [];
  isCampaignChangesLoaded = false;

  budgetChanges: Array<IStatusHistory> = [];
  cpaChanges: Array<IStatusHistory> = [];
  maxConversionChanges: Array<IStatusHistory> = []

  creatorUsers!: any[];
  uploaderUsers!: any[];

  budgetDialogIsOpen = false;
  newBudgetValue = 200;

  cpaDialogIsOpen = false;
  newCpaValue = 200;

  selectedCampaign!: ICampaignDetail;
  selectedAccount!: IAccount;
  isRecentsLoaded = false;

  range: DateRange = new DateRange(subWeeks(startOfToday(), 1), startOfYesterday());

  totals!: ITotals;
  isTotalsLoaded = false;

  filterForm: FormGroup = this.fb.group({
    search: ['', Validators.required],
    creator: ['', Validators.required],
    uploader: ['', Validators.required]
  })

  constructor(
    private statusv4: Statusv4Service,
    private campaignv4: Campaignv4Service,
    private message: MessageService,
    private userv4: Userv4Service,
    private fb: FormBuilder) { }


  ngOnInit(): void {
    this.filterForm.valueChanges
    .pipe(
      debounceTime(600) // ignores very quick secondary daterange event, to avoid loading previous data and to avoid multiple api fetches
    )
    .subscribe((event: any) => {
      this.getRecentCampaigns();
    })
    const searchTerm = this.filterForm.get('search')?.value;
    if (searchTerm) {
      const obfuscator = new Obfuscator(searchTerm);
      const obfuscatedSearchTerm = obfuscator.obfuscate();
      this.filterForm.get('search')?.setValue(obfuscatedSearchTerm);
    }

    this.getRecentCampaigns();

    this.userv4.all().subscribe((users: IUser[]) => {
      this.creatorUsers = users;
      this.uploaderUsers = users;
    });

    this.statusv4.getAllActiveHistory(IStatusChangeType.Campaign).subscribe((changes: Array<IStatusHistory>) => {
      this.campaignChanges = changes;
      this.isCampaignChangesLoaded = true;
    });

    this.statusv4.getAllActiveHistory(IStatusChangeType.Budget).subscribe((changes: Array<IStatusHistory>) => {
      this.budgetChanges = changes;
    });

    this.statusv4.getAllActiveHistory(IStatusChangeType.Cpa).subscribe((changes: Array<IStatusHistory>) => {
      this.cpaChanges = changes;
    });

    this.statusv4.getAllActiveHistory(IStatusChangeType.MaxConversions).subscribe((changes: Array<IStatusHistory>) => {
      this.maxConversionChanges = changes;
    });
  
  }

  getRecentCampaigns() {
    this.isTotalsLoaded = false;
    this.isRecentsLoaded = false;
  
    const searchTerm = this.filterForm.get('search')?.value;
    const obfuscator = new Obfuscator(searchTerm);
    const obfuscatedSearchTerm = obfuscator.obfuscate();
  
    this.campaignv4.getRecentTotals(
      obfuscatedSearchTerm,
      this.filterForm.get('creator')?.value,
      this.filterForm.get('uploader')?.value,
      this.range
    ).subscribe((totals: ITotals) => {
      this.totals = totals;
      this.isTotalsLoaded = true;
    })
  
    this.campaignv4.getRecent(
      obfuscatedSearchTerm,
      this.filterForm.get('creator')?.value,
      this.filterForm.get('uploader')?.value,
      this.range
    ).subscribe((recent) => {
      this.recentCampaigns = recent;
      this.isRecentsLoaded = true;
    })
  }
  

  dateRangeChanged(range: DateRange) {
    this.range = range;
    this.getRecentCampaigns()
  }
  
  changeStatus(campaign: ICampaign) {
    let payload: IStatusPayload = {
      id: campaign.id,
      account_id: campaign.account.id,
      campaign_id: campaign.id,
      ad_group_id: 0,
      type: IStatusChangeType.Campaign,
    }
    
    setTimeout(() => {
      this.isCampaignChangesLoaded = false;
      this.statusv4.getActiveHistory(campaign.account.id, IStatusChangeType.Campaign).subscribe((changes: Array<IStatusHistory>) => {
        this.campaignChanges = changes;
        this.isCampaignChangesLoaded = true;
      })
    }, 100)


    this.statusv4.apply(payload).subscribe({
      complete: () => {},
      error: () => this.message.add({
        severity: 'error',
        summary: 'Could not change status'
      })
    })
  }

  hasStatus(campaign: ICampaign) {
    if (campaign === undefined) {
      return false;
    }

    return this.campaignChanges.find(item => item.entity_id === campaign.id) !== undefined
  }

  /**
   * 
   * @param campaign 
   */
  openBudgetDialog(campaign: ICampaign) {
    this.budgetDialogIsOpen = true;
    this.selectedCampaign = Campaign.detail(campaign);
    this.selectedAccount = campaign.account
    this.newBudgetValue = campaign.budget
  }

  /**
   * 
   * @param entity_id 
   * @returns 
   */
  getBudgetChange(entity_id: number) {
    let change = this.budgetChanges.find(item => item.entity_id === entity_id);

    if (change === undefined) {
      return 0;
    }

    return change.value
  }

  /**
   * 
   */
  changeBudget() {  
    this.statusv4.getAllActiveHistory(IStatusChangeType.Budget).subscribe((changes: Array<IStatusHistory>) => {
      this.budgetChanges = changes;
    })
  }

  /**
   * 
   * @param entity_id 
   * @returns 
   */
  getCpaChange(entity_id: number) {
    let change = this.cpaChanges.find(item => item.entity_id === entity_id);

    if (change === undefined) {
      return 0;
    }

    return change.value
  }

  changeCpa() {
    this.statusv4.getAllActiveHistory(IStatusChangeType.Cpa).subscribe((changes: Array<IStatusHistory>) => {
      this.cpaChanges = changes;
    })

    this.statusv4.getAllActiveHistory(IStatusChangeType.MaxConversions).subscribe((changes: Array<IStatusHistory>) => {
      this.maxConversionChanges = changes;
    })
  }

  /**
   * 
   * @param campaign 
   */
  openCpaDialog(campaign: ICampaign) {
    this.cpaDialogIsOpen = true;
    this.selectedAccount = campaign.account
    this.selectedCampaign = Campaign.detail(campaign);
    this.newCpaValue = parseInt(campaign.target_cpa)
  }

  /**
   * 
   * @param entity_id 
   * @returns 
   */
  hasMaxConversionsChange(entity_id: number) {
    let change = this.maxConversionChanges.find(item => item.entity_id === entity_id);

    if (change === undefined) {
      return false;
    }

    return true
  }

}
