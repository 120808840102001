import { NgIf } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'mave-menu-item',
  imports: [RouterLink, NgIf],
  standalone: true,
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  @Input() label!: string;
  @Input() icon!: string;
  @Input() link!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
