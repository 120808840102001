import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NavbarItemComponent } from './components/navbar-item/navbar-item.component';
import { RouterModule } from '@angular/router';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RangePipe } from './pipes/range.pipe';
import { SummaryToRangePipe } from './pipes/summary-to-range.pipe';
import { ShortenPipe } from './pipes/shorten.pipe';
import { MicronsPipe } from './pipes/microns.pipe';
import { AlertFormComponent } from './forms/alert-form/alert-form.component';
import { CalendarModule } from 'primeng/calendar';
import { VersionComponent } from './components/version/version.component';
import { SplitButtonModule } from 'primeng/splitbutton'
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { AccountStatusPipe } from './pipes/account-status.pipe';
import { MenuModule } from 'primeng/menu';
import { ClickedOutsideDirective } from './directives/clicked-outside.directive';
import { LimitTrailingPipe } from './pipes/limit-trailing.pipe';
import { TitleComponent } from '../mavejs/text/title/title.component';
import { NotifyBadgeComponent } from '../mavejs/base/notify-badge/notify-badge.component';
import { EditableComponent } from '../mavejs/form/editable/editable.component';
import { ActionComponent } from '../mavejs/base/action/action.component';
import { CardComponent } from '../mavejs/base/card/card.component';
import { MenuComponent } from '../mavejs/base/menu/menu.component';
import { LabelComponent } from '../mavejs/text/label/label.component';
import { SubTitleComponent } from '../mavejs/text/sub-title/sub-title.component';
import { ButtonComponent } from '../mavejs/form/button/button.component';
import { LoaderComponent } from '../mavejs/http/loader/loader.component';
import { ModalComponent } from '../mavejs/ui/modal/modal.component';
import { MenuItemComponent } from '../mavejs/base/menu-item/menu-item.component';
import { MenuSplitComponent } from '../mavejs/base/menu-split/menu-split.component';
import { TextboxComponent } from '../mavejs/form/textbox/textbox.component';
import { ValidationComponent } from '../mavejs/form/validation/validation.component';
import { SelectDirective } from '../mavejs/form/select.directive';
import { NotificationsComponent } from './components/notifications/notifications.component';

@NgModule({
    imports: [
        // Components
        TitleComponent,
        NotifyBadgeComponent,
        EditableComponent,
        ActionComponent,
        CardComponent,
        MenuComponent,
        MenuItemComponent,
        MenuSplitComponent,
        LabelComponent,
        SubTitleComponent,
        ButtonComponent,
        LoaderComponent,
        ModalComponent,
        TextboxComponent,
        ValidationComponent,
        SelectDirective,
        NotificationsComponent,
        // Modules
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SplitButtonModule,
        CalendarModule,
        MenuModule,
        ConfirmDialogModule,
        NavbarComponent,
        NotFoundComponent,
        NavbarItemComponent,
        UserMenuComponent,
        SummaryToRangePipe,
        ShortenPipe,
        MicronsPipe,
        AlertFormComponent,
        VersionComponent,
        AccountStatusPipe,
        ClickedOutsideDirective,
        LimitTrailingPipe,
    ],
    providers: [
        ConfirmationService
    ],
    exports: [
        NavbarComponent,
        NotFoundComponent,
        NavbarItemComponent,
        UserMenuComponent,
        SummaryToRangePipe,
        ShortenPipe,
        MicronsPipe,
        AlertFormComponent,
        VersionComponent,
        AccountStatusPipe,
        ClickedOutsideDirective,
        LimitTrailingPipe
    ]
})
export class LibModule { }
