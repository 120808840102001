import { Account, IAccount } from "src/app/account/interfaces/account";
import { IGraphFormatter } from "./igraph-formatter";

export class GraphFillFormatterBuilder implements IGraphFormatter {
  private account: IAccount;
  private value: any;

  constructor(value: any, account: IAccount) {
    this.value = value;
    this.account = account;
  }

  public format(): string {
    return '\n' + Account.getCurrencyCode(this.account) + this.value.cost + '\n' + Account.getCurrencyCode(this.account) + this.value.budget;
  }

  public max(): number {
    return 20
  }

  public min(): number {
      return -50;
  }

}
