import { IAccount } from "src/app/account/interfaces/account";

/**
 * @deprecated
 */
export interface IStatusChange {
    id: number;
    completedAt?: Date
    operation: string;
    operationName: string;
    oldValue: number;
    oldValueFormatted: string;
    value: number;
    valueFormatted: string;
    campaignId: number;
    campaignName: string
    name: string;
    completed: boolean;
    type: string;
    typeName: string;
    csv: boolean;
    authorName: string;
    createdAt: Date;
    firstAdgroupId: number;
    automation: boolean;
    state: string;
    error: string;
}

/**
 * @deprecated
 */
export class StatusChange implements IStatusChange
{
  automation: boolean = false;
  id: number = 0;
  completedAt?: Date | undefined;
  operation: string = '';
  operationName: string = '';
  oldValue: number = 0;
  oldValueFormatted: string = "€ 0,00";
  value: number = 0;
  valueFormatted: string = "€ 0,00";
  campaignId: number = 0;
  campaignName: string = ''
  name: string= '';
  completed: boolean = false;
  type: string = "";
  typeName: string = "";
  csv: boolean = false;
  authorName: string = 'Unknown user';
  createdAt: Date = new Date();
  firstAdgroupId: number = 0;
  state: string = '';
  error: string = '';

  static noop() {
    return {
      id: 0,
      completedAt: new Date(),
      operation: '',
      operationName: '',
      oldValue: 0,
      oldValueFormatted: '€ 0,00',
      value: 0,
      valueFormatted: '€ 0,00',
      campaignId: 0,
      campaignName: 'noop campaign name',
      name: 'name',
      type: "",
      typeName: "",
      csv: false,
      completed: false,
      authorName: 'Unknown user',
      createdAt: new Date(),
      firstAdgroupId: 0,
      automation: false,
      state: '',
      error: ''
    }
  }
}

export enum IStatusChangeType {
  Campaign = 0,
  Ad = 1,
  Device = 2,
  Age = 3,
  Parental = 4,
  Gender = 5,
  Income = 6,
  Budget = 7,
  Cpa = 8,
  MaxConversions = 9,
  None = -1
  // TODO: More to add ...
}

export enum IStatusChangeOperation {
  ENABLE = 0,
  PAUSE = 1,
  CPA = 2,
  BID = 3,
  NONE = 4,
  BUDGET = 5,
  MAXIMIZE_CONVERSIONS = 6
}

export interface IStatusPayload {
  id: number; // Id of the entity type (campaign, ad, device, bid, age, ...)
  account_id: number // Id of the account
  campaign_id: number // Id of related campaign, necessary because entity id is not always campaign id
  ad_group_id: number
  type: IStatusChangeType
  value?: string
}

export interface IStatusChangeResponse {
  state: boolean
  in_progress: boolean
  has_error: boolean
}

export interface IStatusHistory {
  id: number;
  old_value: string;
  value: string;
  entity_id: number;
}

export interface IStatus {
  id: number;
  old_value: string;
  value: string;
  account: IAccount;
  description: string;
  type: number;
  created_at: Date;
}

export interface IStatusPending {
  count: number;
  has_new_manual_changes: boolean;
  has_older_than_two_hours_manual_changes: boolean;
  has_statuses_with_errors: boolean;
}