import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[mvInput]',
  standalone: true
})
export class InputDirective {
  @Input("inputVariant") inputVariant!: string;
  @HostBinding('class') class = 'mv-input';

  constructor() { 
  }

  ngOnInit(): void {
    if (this.inputVariant === undefined) {
      return
    }
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.class = (this.inputVariant === 'bare' ? 'mv-input-bare' : 'mv-input');
  }

}
