import { Component, OnInit } from '@angular/core';
import { IVersion } from '../../interfaces/version';
import { VersionService } from '../../services/version.service';
import { Versionv4Service } from '../versionv4.service';

@Component({
    selector: 'ganal-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss'],
    standalone: true
})
export class VersionComponent implements OnInit {

  version: string = "0.0.0";

  constructor(private versionService: VersionService, private versionv4: Versionv4Service) { }

  ngOnInit(): void {
    this.versionv4.version().subscribe((version) => {
      this.version = version.api + ' (' + version.hash + ')'
    })
  }

}
