<div class="relative">
    <div
        class="flex items-center text-secondary py-2 px-5 mx-2 transition transition-all hover:bg-gray-light active:bg-gray cursor-pointer rounded-full"
        (click)="toggleMenu()">
        <div class="flex items-center mr-3">
            <i class="material-symbols-outlined">account_circle</i>
        </div>
        <div class="font-bold text-sm" *ngIf="user">{{ user.name }}</div>
    </div>

    <mave-menu *ngIf="isMenuOpen" (mouseleave)="toggleMenu()">

        <mave-menu-item *ngIf="user.isSuperAdmin" link="/account/superadmin" label="Admin Dashboard"></mave-menu-item>
        <mave-menu-item *ngIf="user.isSuperAdmin" link="/admin/api_keys" label="Api Access"></mave-menu-item>

        <!-- <mave-menu-item label="Settings"></mave-menu-item> -->
        <mave-menu-item label="User Management" link="/account/permissions"></mave-menu-item>
        <mave-menu-split></mave-menu-split>
        <mave-menu-item icon="logout" label="Logout" (click)="logout()"></mave-menu-item>
    </mave-menu>
</div>
