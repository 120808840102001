import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { Observable } from 'rxjs';
import { IDailySpend } from '../interfaces/ad/idaily-spend';
import {ICampaignChange} from "../../campaign/interfaces/campaign-change";

@Injectable({
  providedIn: 'root'
})
export class AdService {

  constructor(private backend: BackendService) { }

  getAds(accountId: number, adGroupId: number, rangeStringArray: Array<string>, sort: string) {
    const ranges = '&ranges[]=' + rangeStringArray.join('&ranges[]=')

    return this.backend.getAll("/api/v1/ad/metrics/" + adGroupId + "/adgroup/multiple?tenant=" + accountId + ranges + "&sort=" + sort);
  }

  getStatistics(accountId: number, adGroupId: number, rangeStringArray: Array<string>, sort: string) {
    const ranges = '&ranges[]=' + rangeStringArray.join('&ranges[]=')

    return this.backend.getAll("/api/v1/ad/metrics/" + adGroupId + "/adgroup/multiple/statistics?tenant=" + accountId + ranges + "&sort=" + sort);
  }

  getRois(accountId: number, adGroupId: number, rangeStringArray: Array<string>) {
    const ranges = '&ranges[]=' + rangeStringArray.join('&ranges[]=')

    return this.backend.getAll("/api/v1/ad/metrics/" + adGroupId + "/adgroup/multiple/roi?tenant=" + accountId + ranges);
  }

  getDailySpend(accountId: number, adGroupId: number): Observable<IDailySpend> {
    return this.backend.getSingle("/api/v1/ad/" + adGroupId + "/adgroup/daily_spend?tenant=" + accountId);
  }

  getChangeHistoryCampaignOverview(accountId: number): Observable<ICampaignChange[]> {
    return this.backend.getAll("/api/v1/status/account?tenant=" + accountId + "&types=Campaign,Budget,CPA,MaximizeConversions&operations=ENABLE,PAUSE,CPA,BUDGET,MAXIMIZE_CONVERSIONS");
  }

  getChangeHistoryCampaignDetail(accountId: number, campaignId: number): Observable<ICampaignChange[]> {
    return this.backend.getAll("/api/v1/status/campaign/" + campaignId + "?tenant=" + accountId + "&types=Campaign,Ad,Device,Age,Parental,Gender,Income,Budget,CPA,MaximizeConversions&operations=ENABLE,PAUSE,CPA,BID,NONE,BUDGET,MAXIMIZE_CONVERSIONS");
  }

}
