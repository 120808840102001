import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { Observable } from 'rxjs';
import { ITemplate, ITemplateLegacy } from '../classes/template';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private backend: BackendService) { }

  public createTemplate(template: ITemplateLegacy): Observable<ITemplateLegacy> {
    return this.backend.sendPost("/api/v1/template", template);
  }

  public editTemplate(templateId: number, template: ITemplate): Observable<ITemplate> {
    return this.backend.sendPost("/api/v1/template/" + templateId + "/update", template);
  }

  public deleteTemplate(template: ITemplateLegacy): Observable<ITemplateLegacy> {
    return this.backend.sendDelete("/api/v1/template/" + template.id);
  }

  public setDefaultTemplate(template: ITemplateLegacy): Observable<ITemplateLegacy> {
    return this.backend.sendPost("/api/v1/template/" + template.id + "/set_default", {});
  }

}
