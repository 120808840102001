<div class="ga-template-selector__ui">
    <mv-button icon="expand_circle_down" label="Select Template" (click)="panel.toggle($event)"></mv-button>
    <!-- <div class="ga-template-selector__range-selected-label">Selected Ranges: <span class="ga-template-selector__range-selected" *ngFor="let range of selectedRanges">{{ range | range }};</span></div> -->
</div>

<p-overlayPanel #panel>
    <div class="ga-template-selector">
        <div class="ga-template-selector__range-box">
            <div 
                *ngFor="let range of rangeObjects" 
                (click)="selectRange(range)"
                class="ga-template-selector__range" 
                [class.selected]="isSelected(range)">
                {{ range.label | summary  }}
            </div>
        </div>
        <div class="ml-3">
            <mv-title [size]="3">Templates</mv-title>
            <div class="flex flex-col justify-content-between">
                <div class="ga-template-selector__grid">
                    <div (click)="selectTemplate(template)" *ngFor="let template of templates" class="ga-template-selector__item" [ngClass]="{'selected': (selectedTemplateId === template.id)}">
                        <i class="material-symbols-outlined ga-template-selector__default" *ngIf="defaultTemplateId === template.id">page_info</i>
                        {{ template.name }}
                        <div class="ga-template-selector__item-ranges">
                            <span *ngFor="let range of template.ranges">{{ range.name }};</span>
                        </div>
                    </div>
                </div>
                <div class="ga-template-selector__actions">
                    <mv-button label="Save Template" variant="primary"></mv-button>
                    <mv-button class="ml-3" label="Details" [routerLink]="['/template']"></mv-button>
                </div>
            </div>
        </div>
        
    </div>
</p-overlayPanel>
