import { Pipe, PipeTransform } from '@angular/core';
import { StatusType } from '../../classes/status-type';

@Pipe({
  standalone: true,
  name: 'statusType'
})
export class StatusTypePipe implements PipeTransform {

  transform(type: string): unknown {
    return new StatusType(type).render();
  }

}
