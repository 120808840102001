import { Routes } from '@angular/router';
import { HomeViewComponent } from './home-view/home-view.component';
import { RecentCampaignsViewComponent } from './campaign/views/recent-campaigns-view/recent-campaigns-view.component';
import { IndexAdViewComponent } from './ad/views/index-ad-view/index-ad-view.component';
import { IndexCampaignViewComponent } from './campaign/views/index-campaign-view/index-campaign-view.component';
import { NotFoundComponent } from './lib/components/not-found/not-found.component';

export const routes: Routes = [
    { path: '', title: 'Ganal: Home', component: HomeViewComponent},
    { path: 'campaign/account/:id', title: 'Ganal: Campaigns', component: IndexCampaignViewComponent},
    { path: 'campaign/recent', component: RecentCampaignsViewComponent},
    { path: 'ad/:accountId/account/:campaignId/adgroup', component: IndexAdViewComponent},
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: 'product', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
    { path: 'sync', loadChildren: () => import('./sync/sync.module').then(m => m.SyncModule) },
    { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
    { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
    { path: 'template', loadChildren: () => import('./template/template.module').then(m => m.TemplateModule) },
    { path: 'status', loadChildren: () => import('./status/status.module').then(m => m.StatusModule) },
    { path: 'daily-reports', loadChildren: () => import('./daily-reports/daily-reports.module').then(m => m.DailyReportsModule) },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
    { path: 'errors', loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule) },
    { path: '**', component: NotFoundComponent }
  ];
  