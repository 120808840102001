import { Component } from '@angular/core';
import { DateRange } from '../lib/classes/daterange';
import { IStatistics, ITotals } from '../lib/interfaces/statistics';
import { IAccountMetrics } from '../account/interfaces/account-metrics-result';
import { startOfToday, startOfYesterday, subWeeks } from 'date-fns';
import { Accountv4Service } from '../account/services/accountv4.service';
import { AccountService } from '../account/services/account.service';
import { DaterangepickerComponent } from "../lib/ui/daterangepicker/daterangepicker.component";
import { CardComponent } from "../lib/ui/card/card.component";
import { TitleComponent } from "../lib/ui/title/title.component";
import { ButtonComponent } from "../mavejs/form/button/button.component";
import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { GraphBuilder } from '../campaign/classes/graph/graph-builder';
import { FixedColorGraphPainter } from '../campaign/classes/graph/graph-painter';
import { AdjustedRoiGraphValueParser, CostGraphValueParser } from '../campaign/classes/graph/graph-value-parser';
import { GraphOptions } from '../campaign/classes/graph/graph-options';
import { Chart } from 'chart.js';
import { ISortEvent } from '../mavejs/data/datatable/datatable.component';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormsModule } from '@angular/forms';
import { CommonModule, PercentPipe } from '@angular/common';
import { StatisticsComponent } from '../lib/components/statistics/statistics.component';
import { LibModule } from '../lib/lib.module';
import { RouterLink } from '@angular/router';
import { ConversionDirective } from '../lib/directives/conversion.directive';
import { FeatureComponent } from '../feature/feature.component';
import { featureIsActivated } from '../lib/classes/feature';
import { TotalsComponent } from '../lib/components/totals/totals.component';
import { TooltipModule } from 'primeng/tooltip';
import { Account, IAccount } from '../account/interfaces/account';
import { ICommentPreview } from '../comment/classes/comment';
import { Commentv4Service } from '../comment/services/commentv4.service';
import { BadgeModule } from 'primeng/badge';
import { CommentOverviewDialogComponent } from '../comment/components/comment-overview-dialog/comment-overview-dialog.component';
import { MessageService } from 'primeng/api';
import { ConvComponent } from '../lib/components/conv/conv.component';

@Component({
  selector: 'ga-home-view',
  standalone: true,
  templateUrl: './home-view.component.html',
  styleUrl: './home-view.component.css',
  imports: [
    CommonModule,
    FormsModule,
    LibModule,
    StatisticsComponent,
    RouterLink,
    DaterangepickerComponent,
    CardComponent,
    ChartModule,
    TitleComponent,
    TableModule,
    PercentPipe,
    FeatureComponent,
    TotalsComponent,
    TooltipModule,
    BadgeModule,
    CommentOverviewDialogComponent,
    ConvComponent
  ],
  providers: [MessageService]
})
export class HomeViewComponent {

  accountHeaders = [
    { value: '', label: '', placeRight: false, sortable: false },
    { value: 'name', label: 'Name', placeRight: false, sortable: false },
    { value: 'cost', label: 'Cost', placeRight: true, sortable: false },
    { value: 'profit', label: 'Profit', placeRight: true, sortable: false },
    { value: 'roi', label: 'ROI', placeRight: true, sortable: false },
    { value: 'conversions', label: 'Conv', placeRight: true, sortable: false },
    { value: 'costConversions', label: 'Cost/Conv', placeRight: true, sortable: false },
    { value: 'revenue', label: 'Revenue', placeRight: true, sortable: false },
    { value: 'usCPM', label: 'US CPM', placeRight: true, sortable: false },
    { value: 'lastStatusChange', label: 'Last Status', placeRight: true, sortable: false }
  ]

  accountHeadersMobile = [
    { value: '', label: '', placeRight: false, sortable: false },
    { value: 'name', label: 'Name', placeRight: false, sortable: false },
    { value: 'cost', label: 'Cost', placeRight: true, sortable: false },
    { value: 'profit', label: 'Profit', placeRight: true, sortable: false },
    { value: 'roi', label: 'ROI', placeRight: true, sortable: false },
    { value: 'conversions', label: 'Conv', placeRight: true, sortable: false },
    { value: 'costConversions', label: 'Cost/Conv', placeRight: true, sortable: false },
    { value: 'revenue', label: 'Revenue', placeRight: true, sortable: false },
    { value: 'usCPM', label: 'US CPM', placeRight: true, sortable: false }
  ]

  page: number = 0;
  range: DateRange = new DateRange(subWeeks(startOfToday(), 1), startOfYesterday())
  statistics!: IStatistics; // Use totals, this is ganalv3
  totals!: ITotals;
  accountMetrics!: Array<IAccountMetrics>;
  account: IAccount = Account.noop();
  isLoadingStatistics = true;
  isLoadingAccounts = true;
  isLoadingGraph = true;
  sortBy: string = "cost";
  direction: string = "desc";
  currentYear: string = new Date().getFullYear().toString();

  scriptUrl: string = 'https://tech2020.co/r?l=GanalScript';

  graphData: any;
  graphOptions: any;

  commentPreviews: ICommentPreview[] = []
  commentDialogIsOpen = false;
  selectedCommentEntity = 0;

  hoverBackgroundColor = GraphOptions.getHoverBackgroundColor();

  constructor(
    private accountv4: Accountv4Service,
    private accountService: AccountService,
    private commentv4: Commentv4Service
  ) { }

  getAccountMetrics() {
    this.isLoadingAccounts = true;

    this.accountv4.getWithMetrics(this.range).subscribe((accountMetrics: Array<IAccountMetrics>) => {
      this.accountMetrics = accountMetrics;
      this.isLoadingAccounts = false;
    });

    this.isLoadingStatistics = true;

    if (featureIsActivated('account_statistics')) {
      this.accountv4.getTotals(this.range).subscribe((totals: ITotals) => {
        this.totals = totals;
        this.isLoadingStatistics = false;
      })


      this.accountv4.getGraph(this.range).subscribe((graphData) => {
        let moreThan7days = graphData.length > 7;

        let costArray = graphData.map((item: any) => (item.y['cost']));
        let maxCost = Math.max(...costArray);

        this.graphData = new GraphBuilder()
          .push(graphData, new FixedColorGraphPainter('gray'), new AdjustedRoiGraphValueParser(maxCost))
          .push(graphData, new FixedColorGraphPainter('#CE0F0F'), new CostGraphValueParser())
          .build(GraphBuilder.labelsFromGraphData(graphData));

        this.graphOptions = GraphOptions.getAccountGraphOptions(!moreThan7days, Account.noop());
        Chart.register(ChartDataLabels);
        this.isLoadingGraph = false;
      })


    } else {
      this.accountService.getAccountStatistics(this.range).subscribe((statistics: IStatistics) => {
        this.statistics = statistics;
        this.isLoadingStatistics = false;
      })

      this.isLoadingGraph = true;
      this.accountService.getAccountGraph(this.range).subscribe((graphData: any) => {
        let moreThan7days = graphData.length > 7;

        let costArray = graphData.map((item: any) => (item.y['cost']));
        let maxCost = Math.max(...costArray);

        this.graphData = new GraphBuilder()
          .push(graphData, new FixedColorGraphPainter('gray'), new AdjustedRoiGraphValueParser(maxCost))
          .push(graphData, new FixedColorGraphPainter('#CE0F0F'), new CostGraphValueParser())
          .build(GraphBuilder.labelsFromGraphData(graphData));

        this.graphOptions = GraphOptions.getAccountGraphOptions(!moreThan7days, Account.noop());
        Chart.register(ChartDataLabels);
        this.isLoadingGraph = false;
      })
    }

    this.commentv4.previews().subscribe((previews: ICommentPreview[]) => {
      this.commentPreviews = previews;
    })
  }

  dateRangeChanged(range: DateRange) {
    this.range = range;
    this.getAccountMetrics()
  }

  sort(event: ISortEvent) {
    this.sortBy = event.header;
    this.direction = event.direction;
    this.getAccountMetrics();
  }

  /**
   *
   * @deprecated TODO: Change to use a new highlightConversion Pipe
   * @param mvolger
   * @param googleconv
   * @param type
   * @returns
   */
  highlightConversion(mvolger: number, googleconv: number, type: string): String {
    //Conversion in het rood maken als het ene nul is en het andere groter dan twee.

    // Als MVolger < Google conv * 0.9
    // Als MVolger of Google conv ≥ 10
    // En als MVolger/Google conv ≥ 2 of Google/MVolger conv ≥ 2
    // Dan kleinste in het rood zetten

    if (type === 'mvolger') {
      if (mvolger < (googleconv * 0.9)) {
        return 'text-red';
      }

      if (mvolger === 0 && googleconv > 2) {
        return 'text-red';
      }

      if ((googleconv >= 10) && googleconv > mvolger) {
        if (googleconv / mvolger >= 2) {
          return 'text-red';
        }
      }
    }

    if (type === 'google') {
      if (googleconv === 0 && mvolger > 2) {
        return 'text-red';
      }

      if ((mvolger >= 10) && mvolger > googleconv) {
        if (mvolger / googleconv >= 2) {
          return 'text-red';
        }
      }
    }

    return '';
  }

  get showGraph() {
    if (this.graphData === undefined) {
      return false;
    }

    return this.graphData.datasets[0].data.length > 1;
  }

  getComment(entityId: number): ICommentPreview {
    return this.commentPreviews.find((cp: ICommentPreview) => cp.entity_id === entityId) ?? {
      account_id: 0,
      entity_id: 0,
      count: 0,
      last_updated: new Date()
    };
  }

  /**
 *
 * @param campaign
 */
  openCommentDialog(entityId: number) {
    this.commentDialogIsOpen = true;
    this.selectedCommentEntity = entityId;
  }


  changeComment() {
    this.commentv4.previews().subscribe((previews: ICommentPreview[]) => {
      this.commentPreviews = previews;
    })
  }
}
