import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateRange, Ranges } from './ranges';
import { InputDirective } from '../input/input.directive';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { startOfMonth, getDaysInMonth, addDays, subDays, isToday, parse, isEqual, isAfter, isBefore, intervalToDuration, format, sub, add, Duration } from 'date-fns'
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'mv-daterangepicker',
  standalone: true,
  imports: [NgIf, NgFor, DatePipe],
  templateUrl: './daterangepicker.component.html',
  styleUrl: './daterangepicker.component.css',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('overlayAnimation', [
        state(
            'visibleTouchUI',
            style({
                transform: 'translate(-50%,-50%)',
                opacity: 1
            })
        ),
        transition('void => visible', [style({ opacity: 0, transform: 'scaleY(0.8)' }), animate('{{showTransitionParams}}', style({ opacity: 1, transform: '*' }))]),
        transition('visible => void', [animate('{{hideTransitionParams}}', style({ opacity: 0 }))]),
        transition('void => visibleTouchUI', [style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }), animate('{{showTransitionParams}}')]),
        transition('visibleTouchUI => void', [
            animate(
                '{{hideTransitionParams}}',
                style({
                    opacity: 0,
                    transform: 'translate3d(-50%, -40%, 0) scale(0.9)'
                })
            )
        ])
    ])
],
})
export class DaterangepickerComponent implements OnInit {

  @Input() date!: DateRange

  @Output('dates') dates = new EventEmitter<DateRange>()
  @ViewChild('pickerPanel') pickerPanel!: ElementRef
  @ViewChild('pickerInput') pickerInput!: ElementRef
  @ViewChild('monthTitle') monthTitle!: ElementRef

  showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';
  hideTransitionOptions: string = '.1s linear';

  showPanel = false;
  months!: Array<Month>;
  today: Date = new Date();
  todayString: string = format(this.today, 'yyyy-MM-dd')
  isSelecting = false;
  firstDaySelection!: Date
  lastDaySelection!: Date
  range!: DateRange;
  rangePresets = [
    {label: 'Yesterday', value: "YESTERDAY"},
    {label: 'Last 7 Days', value: "LAST_7_DAYS"},
    {label: 'This Month', value: "THIS_MONTH"},
    {label: 'Last Month', value: "LAST_MONTH"},
    {label: 'Last 30 Days', value: "LAST_30_DAYS"},
    {label: 'One Week Ago', value: "ONE_WEEK_AGO"},
    {label: 'Two Weeks Ago', value: "TWO_WEEKS_AGO"},
    {label: 'Three Weeks Ago', value: "THREE_WEEKS_AGO"},
    {label: 'This Year', value: "THIS_YEAR"},
    {label: 'Custom', value: 'CUSTOM'}
  ]
  presetValue: string = 'CUSTOM';
  referenceDateForMonths = new Date();
  isGeneratingMonths = false;

  weekLabels = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun"
  ]

  monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "Juli",
    "Augustus",
    "September",
    "October",
    "November",
    "December"
  ]

  constructor() {

  }

  ngOnInit(): void {
    this.generateDaysByMonths();
    this.range = this.date;

    this.getDates(this.range.getFrom(), this.range.getTo())
  }

  get selectedPresetLabel() {
    return this.rangePresets.find((item) => item.value === this.presetValue)?.label
  }

  get render() {
    if (this.range.getFromFormattedView() === this.range.getToFormattedView()) {
      return this.range.getFromFormatted()
    }

    return this.range.getFromFormattedView() + " - " + this.range.getToFormattedView()
  }

  getWeekdaysUntil(month: Month): Array<number> {
    let weekdays = 0;
    let weekdayArray = [];
    let currentWeekdayLabel = '';

    while (month.getFirstWeekDay() !== currentWeekdayLabel) {
      currentWeekdayLabel = this.weekLabels[weekdays];
      weekdayArray.push(weekdays);
      weekdays++;

      if (weekdays > 7) {
        console.error("Wtf, this loop is infinite now");
        return [];
      }
    }

    weekdayArray.pop()

    return weekdayArray;
  }

  handleSelection() {
    for (let month of this.months) {
      for (let day of month.days) {
        day.selected = (isBefore(day.day, this.lastDaySelection) || isEqual(day.day, this.lastDaySelection)) &&
          (isAfter(day.day, this.firstDaySelection) || isEqual(day.day, this.firstDaySelection))
      }
    }

    return this.months
  }

  /**
   * 
   * @param startString 
   * @param endString 
   */
  getDates(start: Date, end: Date) {
    this.range = new DateRange(start, end);

    this.dates.emit(this.range);
    this.firstDaySelection = this.range.getFrom();
    this.lastDaySelection = this.range.getTo();
    this.presetValue = Ranges.getRangesReversed()[this.range.toString()] ?? 'CUSTOM'
    this.handleSelection()
  }

  /**
   * 
   * @param day 
   */
  select(date: IDay, event: Event) {
    event?.stopPropagation()
    let day = date.day

    if (this.isSelecting && (isBefore(this.firstDaySelection, day) || isEqual(this.firstDaySelection, day))) {
      this.isSelecting = false;
      this.lastDaySelection = day;

      this.getDates(this.firstDaySelection, this.lastDaySelection);
      this.showPanel = false;
    } else {
      this.isSelecting = true;
      this.firstDaySelection = day;
      this.lastDaySelection = new Date();
      this.handleSelection()

      date.selected = true;
    }
  }

  /**
   * 
   * @returns 
   */
  generateDaysByMonths(): void {
    this.isGeneratingMonths = true;

    let lastMonth = startOfMonth(sub(this.referenceDateForMonths, { months: 1 }))
    let months = [
      new Month(lastMonth.getMonth(), lastMonth.getFullYear(), []),
      new Month(this.referenceDateForMonths.getMonth(), this.referenceDateForMonths.getFullYear(), [])
    ]

    for (let month of months) {
      let day = new Date(month.year, month.month, 1)
      let days: Array<IDay> = [];
      // let thisMonthFormatted = month.year + "-" + (month.month + 1) + "-01"

      for (let index = 0; index < getDaysInMonth((month.month + 1)); index++) {
        days.push({ day: day, selected: false });
        day = addDays(day, 1);
      }

      month.days = days;
    }

    this.isGeneratingMonths = false;
    this.months = months
  }

  @HostListener("window:click", ['$event'])
  onClickOutside(event: Event) {
    if (!this.showPanel) {
      return;
    }

    this.showPanel = false;
  }

  get duration(): Duration {
    let duration = intervalToDuration({ start: this.range.getFrom(), end: this.range.getTo() });

    if (duration === null || duration === undefined) {
      return { days: 0 }
    }

    return duration;
  }

  next(event: Event) {
    event.stopPropagation();

    console.log(this.duration.days);

    let start = addDays(this.range.getFrom(), (this.duration.days ?? 0) + 1)
    let end = addDays(this.range.getTo(), (this.duration.days ?? 0) + 1);

    let range = new DateRange(start, end);

    this.getDates(range.getFrom(), range.getTo());
  }

  back(event: Event) {
    event.stopPropagation();
    
    let start = subDays(this.range.getFrom(), (this.duration.days ?? 0) + 1)
    let end = subDays(this.range.getTo(), (this.duration.days ?? 0) + 1);

    let range = new DateRange(start, end);

    this.getDates(range.getFrom(), range.getTo());
  }

  toggle(event: Event) {
    event?.stopPropagation();
    this.showPanel = !this.showPanel
  }

  selectPreset(preset: any) {
    let range = Ranges.getRanges()[preset.value] ?? new DateRange(this.range.getFrom(), this.range.getTo())

    this.getDates(range.getFrom(), range.getTo());
  }

  nextMonths(event: Event) {
    event.stopPropagation();

    this.months = []
    this.referenceDateForMonths = add(this.referenceDateForMonths, { months: 1 }) 
    this.generateDaysByMonths();
    this.getDates(this.range.getFrom(), this.range.getTo())
  } 

  previousMonths(event: Event) {
    event.stopPropagation();

    this.months = [];
    this.referenceDateForMonths = sub(this.referenceDateForMonths, { months: 1 }) 
    this.generateDaysByMonths();
    this.getDates(this.range.getFrom(), this.range.getTo())
  }
}

class IDay {
  day!: Date;
  selected: boolean = false;
}

class Month {
  month: number;
  year: number;
  days: Array<IDay>;

  constructor(month: number, year: number, days: Array<IDay>) {
    this.month = month;
    this.year = year;
    this.days = days;
  }

  getFirstWeekDay(): string {
    return format(this.days[0].day, 'EEE');
  }
}