import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ga-workbar',
  standalone: true,
  templateUrl: './workbar.component.html',
  styleUrls: ['./workbar.component.scss']
})
export class WorkbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
