import { IComment } from "src/app/comment/classes/comment";
import { ICommentThread } from "src/app/comment/interfaces/icomment-thread";
import { ICommentable } from "src/app/comment/interfaces/icommentable";


export interface IAccountDatabaseInfo {
  name: string;
  url: string;
}

export interface IAccount extends ICommentable {
  id: number;
  name: string;
  activeProductName: string;
  mccId: string;
  customerId: string;
  comment?: IComment; // Deprecated
  lastStatusChange: string;
  createdAt: string;
  databaseInfo?: IAccountDatabaseInfo;
  correctScriptVersion: boolean;
  lastSevenDaysYoutubePercentage: number;
  lastSevenDaysYoutubePercentageFormatted: string;
  status: string;
  statusValue: number;
  usCpmFormatted: string;
  currency: string;
}

export class Account {
  static noop(): IAccount {
    return { id: 0, name: '', commentThreadId: 0, currency: 'USD', commentThread: { id: 0, commentCount: 0, isRead: true, comments: [], type: 1 }, activeProductName: '', mccId: '', customerId: '', createdAt: '', lastStatusChange: '', correctScriptVersion: true, lastSevenDaysYoutubePercentage: 0, lastSevenDaysYoutubePercentageFormatted: '0%', status: '', statusValue: 2, usCpmFormatted: "€ 0" }
  }

  static getCurrencyCode(account: IAccount): string {
    if (account === undefined) {
      return '$';
    }
    return account.currency === 'EUR' ? '€' : '$'
  }
}
