import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRange } from 'src/app/lib/classes/daterange';
import Url from 'src/app/lib/classes/url';
import { IAd, IAdTotals } from '../classes/ad/ad';

@Injectable({
  providedIn: 'root'
})
export class Adv4Service {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @param campaignId 
   * @param range 
   * @returns 
   */
  getAds(campaignId: number, range: DateRange): Observable<Array<IAd>> {
    let url = new Url("/ad/by_campaign/" + campaignId)
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted());

    return this.http.get<Array<IAd>>(url.build());
  }

  /**
   * 
   * @param campaignId 
   * @param range 
   * @returns 
   */
  getAdTotals(campaignId: number, range: DateRange): Observable<IAdTotals> {
    let url = new Url("/ad/by_campaign/" + campaignId + "/totals")
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted());
  
    return this.http.get<IAdTotals>(url.build());
  }

}
