import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import Url from 'src/app/lib/classes/url';
import { ITemplateRange, ITemplate } from '../classes/template';
import { DateRange } from 'src/app/lib/classes/daterange';

@Injectable({
  providedIn: 'root'
})
export class Templatev4Service {

  private templates!: Observable<Array<ITemplate>>

  constructor(private http: HttpClient) { }

  public preloadTemplates(): void {
    let url = new Url("/template")

    this.templates = this.http.get<Array<ITemplate>>(url.build()).pipe(
      shareReplay(1)
    )
  }

  /**
   * 
   * @returns 
   */
  public getTemplates(): Observable<Array<ITemplate>> {
    return this.templates;
  }

  /**
   * 
   * @returns 
   */
  public createTemplate(template: ITemplate): Observable<ITemplate> {
    let url = new Url("/template/");

    return this.http.post<ITemplate>(url.build(), template);
  }

  /**
   * 
   * @param templateId 
   * @returns 
   */
  public createTemplateRanges(templateId: number, ranges: Array<ITemplateRange>): Observable<void> {
    let url = new Url("/template/" + templateId + "/ranges");

    return this.http.post<void>(url.build(), ranges);
  }

  /**
   * 
   * @param templateId 
   * @returns 
   */
  public deleteTemplate(templateId: number): Observable<ITemplate> {
    let url = new Url("/template/" + templateId)

    return this.http.delete<ITemplate>(url.build());
  }

  /**
   * 
   * @param templateId 
   */
  public setDefaultTemplate(templateId: number): void{
    localStorage.setItem("defaultTemplate", templateId.toString())
  }

  /**
   * 
   * @returns 
   */
  public getDefaultTemplateId(): number {
    return parseInt(localStorage.getItem("defaultTemplate") ?? '0')
  }

}
