import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Dialog, DialogModule } from 'primeng/dialog';
import { IAccount } from 'src/app/account/interfaces/account';
import { IDevice } from 'src/app/adgroup/interfaces/device';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { BidFormComponent } from '../../forms/bid-form/bid-form.component';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';

@Component({
  selector: 'ga-bid',
  standalone: true,
  imports: [DialogModule, TitleComponent, BidFormComponent, ButtonComponent],
  templateUrl: './bid.component.html',
  styleUrl: './bid.component.css'
})
export class BidComponent implements AfterViewInit {

  @Input() open!: boolean;
  @Input() account!: IAccount;
  @Input() device!: IDevice;
  @Input() campaignId!: number;

  @Output() openChange = new EventEmitter<boolean>();
  @Output() bidChange = new EventEmitter<string>();
  @Output() statusChange = new EventEmitter<void>();

  @ViewChild('dialog') dialog!: Dialog
  @ViewChild('bidForm') bidForm!: BidFormComponent

  ngAfterViewInit(): void {
    this.dialog.visibleChange.subscribe(() => {
      this.openChange.emit(false);
    })
  }

  close(event: Event) {
    this.dialog.close(event);
  }

  toggle(isOpen: boolean) {
    this.openChange.emit(isOpen);
  }

}
