import { IGraphFormatter } from "./igraph-formatter";

export class GraphGeneralFormatter implements IGraphFormatter {

  value: any;

  constructor(value: any) {
    this.value = value;
  }

  format(): string {
    return this.value;
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }

}
