export class Shorten {

    static shorten(text: string, maxChars = 20, side = 'front') {
        if (maxChars > text.length) {
            return text
        }
        
        if (side === 'front') {
          const start = text.length - maxChars
    
          return '...' + text.substr(start, text.length)
        }
    
        return text.substr(0, maxChars) + '...'
    }

    static ad(text: string) {
        if (!text.includes('YT')) {
            return this.shorten(text, 25)
        }
        
        return '...' + text.split('YT')[1].trim()
    }

}
