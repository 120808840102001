<mave-modal title="Create Template" #templateModal>

  <div class="font-bold text-sm text-black mb-3">Make for ranges: <span class="text-primary">{{ selectedRanges.join(",") | summaries }}</span></div>

  <form [formGroup]="templateForm">
    <mave-textbox label="Name" name="name" [control]="name">
      <input type="text" formControlName="name" maveInput />
    </mave-textbox>
  </form>

  <div footer>
    <mave-button [loading]="templateIsSaving" label="Save" (click)="saveTemplate()"></mave-button>
  </div>
</mave-modal>

<!--  <div class="preset-ranges" ganalClickedOutside (clickOutside)="clickedOutside()"> Needs this fix-->
<div class="preset-ranges">
  <div class="flex flex-row justify-around">
    <button class="bg-gray-light text-black-lighter font-bold py-2 px-3 rounded-md mr-2 " (click)="showHideMenu()">
      <span *ngIf="selectedRanges.length > 1">{{selectedRanges.length}} selected</span>
      <span *ngIf="selectedRanges.length === 1">{{allRanges[selectedRanges[0]].name | summary}}</span>
    </button>

    <button class="bg-gray-light text-black-lighter font-bold py-2 px-3 rounded-md ml-2"
      (click)="showHideTemplateMenu()">
      <span>Templates</span>
    </button>
  </div>


  <!-- Range menu -->
  <ul class="rounded rounded-lg bg-white shadow shadow-xs border border-gray" *ngIf="showMenu">
    <li *ngFor="let range of allRanges" class="">
      <!-- hacky solution for range select -->
      <div [ngClass]="{'bg-gray-light': selectedRanges.includes(range.value)}" class="rounded-md range">
        <mave-action [label]="range.name | summary" type="secondary" (click)="selectRange(range)">
        </mave-action>
      </div>
    </li>
  </ul>

  <!-- Template menu -->
  <ul class="rounded rounded-lg bg-white shadow shadow-xs border border-gray" *ngIf="showTemplateMenu">
    <li *ngFor="let template of templates" class="">
      <mave-action [label]="template.name" type="secondary" (click)="changeTemplate(template)">
      </mave-action>
    </li>
    <li>
      <mave-button (click)="openTemplateModal()" label="Save Template"></mave-button>
    </li>
    <li class="-my-2">
      <mave-button size="small" routerLink="/template" label="Details"></mave-button>
    </li>
  </ul>
</div>