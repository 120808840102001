import { IUser } from "src/app/auth/classes/user";

/**
 * @deprecated
*/
export interface ICommentLegacy {

  id: number;
  text: string;
  authorName: string;
  authorId: number;
  threadId: number;
  isYou: boolean;
  createdAt: string;

}

export interface IAuthor {
  name: string;
}

export class Comment {

  static noop(): ICommentLegacy {
    return { id: 0, text: "", authorName: "Nobody", authorId: 0, threadId: 0, isYou: false, createdAt: "" }
  }

}


export enum CommentType {
  ACCOUNT = 0,
  CAMPAIGN = 1
}

export interface IComment {
  id: number;
  text: string;
  author: IUser;
  created_at: string;
}

export interface ICommentPreview {
  account_id: number;
  entity_id: number;
  count: number;
  last_updated: Date
}