import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IParental } from 'src/app/ad/classes/demographics/demographics';
import { DateRange } from 'src/app/lib/classes/daterange';
import Url from 'src/app/lib/classes/url';

@Injectable({
  providedIn: 'root'
})
export class Parentalv4Service {

  constructor(private http: HttpClient) { }

  getParentals(campaignId: number, range: DateRange): Observable<Array<IParental>> {
    let url = new Url("/demographics/parental/by_campaign/" + campaignId)
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted());

    return this.http.get<Array<IParental>>(url.build());
  }

}
