import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import Url from '../../lib/classes/url';
import { parseISO } from 'date-fns';
import { ICredentials } from '../classes/credentials';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Authv4Service {

  @Output() authenticationEmitter: EventEmitter<IAuthState> = new EventEmitter();

  constructor(private http: HttpClient) { }

  set(creds: ICredentials) {
    localStorage.setItem('Ganalv4_userId', creds.id.toString())
    localStorage.setItem('Ganalv4_userEmail', creds.email)
    localStorage.setItem('Ganalv4_userFullname', creds.fullname)
    localStorage.setItem('Ganalv4_createdAt', creds.created_at.toString())
    localStorage.setItem('Ganalv4_lastLogin', creds.last_login.toString())
    localStorage.setItem('Ganalv4_token', creds.token)
  }

  get(): ICredentials {
    return {
      id: parseInt(localStorage.getItem('Ganalv4_userId') ?? ''),
      fullname: localStorage.getItem('Ganalv4_userFullname') ?? '',
      email: localStorage.getItem('Ganalv4_userEmail') ?? '',
      created_at: parseISO(localStorage.getItem('Ganalv4_createdAt') ?? ''),
      last_login: parseISO(localStorage.getItem('Ganalv4_lastLogin') ?? ''),
      token: localStorage.getItem('Ganalv4_token') ?? ''
    }
  }

  login(email: string, password: string): Observable<ICredentials> {
    let url = new Url("/auth/login");

    return this.http.post<ICredentials>(url.build(), {username: email, password: password})
  }

  logout() {
    localStorage.removeItem('Ganalv4_userId')
    localStorage.removeItem('Ganalv4_userEmail')
    localStorage.removeItem('Ganalv4_userFullname')
    localStorage.removeItem('Ganalv4_createdAt')
    localStorage.removeItem('Ganalv4_expiredAt')
    localStorage.removeItem('Ganalv4_token')
  }

  legacyTokenPassing(ganalToken: string): Observable<ILegacyToken> {
    let url = new Url("/auth/legacy_token");

    return this.http.post<ILegacyToken>(url.build(), {token: ganalToken})
  }

  getAuth() {
    return this.authenticationEmitter;
  }

  emitIsAuthenticatedEvent() {
    this.authenticationEmitter.emit({valid: true})
  }

  isExpired() {
    // let expiredAtRaw = localStorage.getItem('Ganalv4_expiredAt');


    // if (expiredAtRaw === undefined || expiredAtRaw === null) {
    //   return true;
    // }

    // let expirationDate = parseISO(expiredAtRaw);


    // return expirationDate < new Date()

    // For now it doesn't expire
    return false;
  }

  hasToken() {
    return localStorage.getItem('Ganalv4_token') !== null;
  }

  toggleBackgrounds() {
    localStorage.setItem('ganal.has_backgrounds', this.hasBackgrounds() ? 'false' : 'true');
  }

  hasBackgrounds() {
    if (localStorage.getItem('ganal.has_backgrounds') === null) {
      return true;
    }
    
    return localStorage.getItem('ganal.has_backgrounds') === 'true';
  }

}


interface IAuthState {
  valid: boolean
}

interface ILegacyToken {
  token: string;
}