import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'campaignTypeIcon',
  standalone: true
})
export class CampaignTypeIconPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
      // const campaignTypes = {
      //   "0": "Unspecified",
      //   "1": "Unknown",
      //   "2": "Search",
      //   "3": "Display",
      //   "4": "Shopping",
      //   "5": "Hotel",
      //   "6": "Video",
      //   "7": "Multi channel",
      //   "8": "Local",
      //   "9": "Smart",
      //   "10": "Performance max",
      //   "11": "Local services",
      //   "12": "Discovery",
      //   "13": "Travel",
      //   "-1": "Unrecognized",
      // }
  
      let campaignIconTypes: IconMapping = {
        "0": "help_outline",
        "1": "help_outline",
        "2": "search",
        "3": "help_outline",
        "4": "shopping_cart",
        "5": "hotel",
        "6": "videocam",
        "7": "splitscreen",
        "8": "help_outline",
        "9": "help_outline",
        "10": "battery_charging_full",
        "11": "help_outline",
        "12": "explore",
        "13": "flight",
        "-1": "help_outline",
      }
  
      if (value === undefined) {
        return "help_outline";
      }

      return campaignIconTypes[value.toString()] ?? "help_outline";
  }

}

interface IconMapping {
  [type: string]: string
}