import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonComponent } from "../../../lib/ui/button/button.component";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Ranges } from '../../../lib/ui/daterangepicker/ranges';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ITemplate } from '../../classes/template';
import { TitleComponent } from "../../../lib/ui/title/title.component";
import { SummaryPipe } from 'src/app/lib/pipes/summary.pipe';
import { Templatev4Service } from '../../services/templatev4.service';
import { DateRange } from 'src/app/lib/classes/daterange';
import { RangePipe } from '../../pipes/range.pipe';
import { RouterLink } from '@angular/router';


@Component({
    selector: 'ga-template-selector',
    standalone: true,
    templateUrl: './template-selector.component.html',
    styleUrl: './template-selector.component.css',
    imports: [NgIf, NgFor, ButtonComponent, OverlayPanelModule, SummaryPipe, TitleComponent, RangePipe, RouterLink, NgClass]
})
export class TemplateSelectorComponent implements OnInit{

  @Input() ranges!: DateRange[]

  rangeMapping = Ranges.getRanges()
  rangeLabels = Object.keys(this.rangeMapping)
  rangeObjects: Array<IRangeObject> = []
  templates!: Array<ITemplate>
  selectedRanges: Array<DateRange> = []
  isTemplateSelected = false;
  defaultTemplateId = 0;
  selectedTemplateId = 0;

  @Output() change: EventEmitter<Array<DateRange>> = new EventEmitter()

  constructor(private templatev4: Templatev4Service) {}

  ngOnInit(): void {
    this.templatev4.getTemplates().subscribe((templates: Array<ITemplate>) => this.templates = templates)
    this.defaultTemplateId = this.templatev4.getDefaultTemplateId();
    this.selectedTemplateId = this.templatev4.getDefaultTemplateId();
    this.selectedRanges = this.ranges;

    this.rangeObjects = this.rangeLabels.map((label: string) => {
      return {
        label: label,
        range: this.rangeMapping[label]
      }
    })
  }

  get rangeObjectsSelected() {
    return this.rangeObjects.filter((rangeObject: IRangeObject) => {
      return this.isSelected(rangeObject)
    })
  }

  get hasCustomRange() {
    for (let range of this.selectedRanges) {
      if (Ranges.getRangesReversed()[range.toString()] === undefined) {
        return true
      }
    }

    return false
  }

  isSelected(rangeObject: IRangeObject) {
    if (rangeObject.label === 'CUSTOM') {
      return this.hasCustomRange;
    }

    return this.selectedRanges.findIndex((range: DateRange) => {
      return range.toString() === rangeObject.range?.toString()
    }) !== -1
  }

  selectRange(rangeObject: IRangeObject) {
    if (this.isTemplateSelected) {
      this.selectedRanges = []
    }

    this.isTemplateSelected = false;
    this.selectedTemplateId = 0;

    if (this.isSelected(rangeObject)) {
      let index = this.selectedRanges.findIndex((range: DateRange) => range.toString() === rangeObject.range?.toString())

      this.selectedRanges.splice(index, 1);

      return this.change.emit(this.selectedRanges);
    }

    if (rangeObject.range !== undefined && !this.isSelected(rangeObject)) {
      this.selectedRanges.push(rangeObject.range);

      return this.change.emit(this.selectedRanges);
    }    
  }

  selectTemplate(template: ITemplate) {
    this.selectedRanges = []
    this.isTemplateSelected = true
    this.selectedTemplateId = template.id

    for (let range of template.ranges) {
      if (range.from_as_date !== undefined && range.to_as_date !== undefined) {
        if (range.is_custom) {
          this.selectedRanges.push(new DateRange(
            range.from_as_date,
            range.to_as_date
          ))
        } else {
          this.selectedRanges.push(Ranges.getRanges()[range.preset] ?? new DateRange(
            range.from_as_date,
            range.to_as_date
          ))
        }

      }
    }

    this.change.emit(this.selectedRanges);
  }

}

interface IRangeObject {
  label: string;
  range?: DateRange;
}