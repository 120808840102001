<form [formGroup]="alertForm" (submit)="submitAlert()">

  <mave-textbox label="Message" name="message" [control]="message">
    <input formControlName="message" maveInput>
  </mave-textbox>

  <div>
    <mave-button [loading]="isLoadingAlertSend" label="Send Alert" class="mr-2" (click)="submitAlert()"></mave-button>
    <mave-button [loading]="isLoadingAlertClear" variant="secondary" label="Clear" (click)="clearAlert()"></mave-button>
  </div>

</form>
