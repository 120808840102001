<div>
    <mv-card [hasHeader]="false">
        <div body>
            <ga-totals *ngIf="isTotalsLoaded" [totals]="totals"></ga-totals>
        </div>
    </mv-card>

    <div class="h-4"></div>

    <ga-cpa-dialog 
        (submitted)="changeCpa()"
        [(campaign)]="selectedCampaign" 
        [(cpa)]="newCpaValue"
        [(open)]="cpaDialogIsOpen"
        [account]="selectedAccount">
    </ga-cpa-dialog>

    <ga-budget-dialog
        (submitted)="changeBudget()"
        [(campaign)]="selectedCampaign" 
        [(budget)]="newBudgetValue"
        [(open)]="budgetDialogIsOpen"
        [account]="selectedAccount">
    </ga-budget-dialog>

    <mv-card [table]="true">
        <div header>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <mv-daterangepicker class="mr-3" (dates)="dateRangeChanged($event)" [date]="range"></mv-daterangepicker>
                    <form class="flex items-center justify-content-end" [formGroup]="filterForm">
                        <div class="mr-3">
                            <mv-input>
                                <div button>
                                    <div class="mv-input-button">
                                        <div class="material-symbols-outlined">search</div>
                                    </div>
                                </div>
                                <input type="text" formControlName="search" placeholder="Search" class="mv-input-bare">
                            </mv-input>
                        </div>
                        <div class="mr-3">
                            <p-dropdown styleClass="mv-dropdown" optionLabel="name" optionValue="id" [options]="creatorUsers" placeholder="Creator" [showClear]="true" formControlName="creator"></p-dropdown>
                        </div>
                        <div class="mr-3">
                            <p-dropdown styleClass="mv-dropdown" optionLabel="name" optionValue="id" [options]="uploaderUsers" placeholder="Uploader" [showClear]="true" formControlName="uploader"></p-dropdown>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div body>
            <p-table 
            *ngIf="isRecentsLoaded" 
            [value]="recentCampaigns"  
            styleClass="p-datatable-striped"
            rowGroupMode="subheader" 
            sortField="first_spend_date" 
            [sortOrder]="-1"
            sortMode="single" 
            groupRowsBy="first_spend_date" 
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th [width]="10"></th>
                        <th>Name</th>
                        <th class="text-right">Account Name</th>
                        <th class="text-right">Budget</th>
                        <th class="text-right">CPA</th>
                        <th class="text-right">Cost</th>
                        <th class="text-right">Profit</th>
                        <th class="text-right">ROI</th>
                        <th class="text-right">Conv</th>
                        <th class="text-right">Cost/Conv</th>
                        <th class="text-right">Revenue</th>
                        <th class="text-right">YT.COM</th>
                        <th class="text-right">CTR</th>
                        <th class="text-right">Start Date</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-campaign>
                    <tr>
                        <td>
                            <p-inputSwitch  
                                *ngIf="isCampaignChangesLoaded && campaign.status !== undefined" 
                                styleClass="-my-2" 
                                [class.red]="campaign.status === 2 && hasStatus(campaign)"
                                [class.yellow]="campaign.status !== 2 && hasStatus(campaign)"
                                [ngModel]="campaign.status === 2 && !hasStatus(campaign) || campaign.status !== 2 && hasStatus(campaign)" 
                                (ngModelChange)="changeStatus(campaign)">
                            </p-inputSwitch>
                            <p-skeleton styleClass="-my-2" *ngIf="!isCampaignChangesLoaded" height="28px" width="48px" borderRadius="50px"></p-skeleton>
                        </td>
                        <td>
                            <div class="flex items-center">
                                <div class="material-symbols-outlined icon-fill text-md mr-3">{{ campaign.type | campaignTypeIcon }}</div>
                                <a [routerLink]="['/ad/' + campaign.account.id + '/account/' + campaign.id + '/adgroup']" class="font-bold text-secondary">{{ campaign.name ?? '-' }}</a>
                            </div>
                        </td>
                        <td class="font-mono">
                            <div class="flex items-center justify-end">{{ campaign.account.name }}</div>
                        </td>
                        <td class="font-mono flex items-center justify-end">
                            <div class="flex items-center justify-end">
                                <span class="ga-recent-campaigns-view-edit material-symbols-outlined" (click)="openBudgetDialog(campaign)">edit</span>
                                <span 
                                    class="ga-recent-campaigns-view-edit-label"
                                    pBadge 
                                    severity="success"  
                                    [badgeDisabled]="getBudgetChange(campaign.id) === 0" 
                                    [value]="(getBudgetChange(campaign.id) | currency) ?? '0$'">
                                    {{ (campaign.budget | currency: campaign.account.currency:undefined:'1.0' ) ?? '-' }}
                                </span>
                            </div>
                        </td>
                        <td class="font-mono">
                            <div class="flex items-center justify-end">
                                <span class="ga-recent-campaigns-view-edit material-symbols-outlined" (click)="openCpaDialog(campaign)">edit</span>
                                <span 
                                    class="ga-recent-campaigns-view-edit-label" 
                                    *ngIf="!hasMaxConversionsChange(campaign.id)"
                                    pBadge 
                                    severity="success"  
                                    [badgeDisabled]="getCpaChange(campaign.id) === 0" 
                                    [value]="(getCpaChange(campaign.id) | currency) ?? '0$'">
                                    {{ (campaign.target_cpa | currency: campaign.account.currency) ?? '-' }}
                                </span>
                                <span 
                                    *ngIf="hasMaxConversionsChange(campaign.id)" 
                                    class="ga-recent-campaigns-view-edit-label" 
                                    pBadge
                                    severity="success" 
                                    [badgeDisabled]="!hasMaxConversionsChange(campaign.id)" 
                                    value="MAX">
                                    {{ (campaign.target_cpa | currency: campaign.account.currency) ?? '-' }}
                                </span>
                            </div>
                        </td>
                        <td class="font-mono">{{ (campaign.total_cost | currency: campaign.account.currency:undefined:'1.0') ?? '-' }}</td>
                        <td class="font-mono">{{ (campaign.total_profit | currency: campaign.account.currency:undefined:'1.0') ?? '-' }}</td>
                        <td class="font-mono">
                            {{ (campaign.total_profit / campaign.total_cost | percent) ?? '-%' }}
                        </td>
                        <td class="font-mono">
                            <span class="font-bold" gaConversion [mvolger]="campaign.total_conversions_tracked" [googleconv]="campaign.total_conversions" type="mvolger">{{ campaign.total_conversions_tracked ?? '-' }}</span>/
                            <span class="text-gray" gaConversion [mvolger]="campaign.total_conversions_tracked" [googleconv]="campaign.total_conversions" type="google">{{ campaign.total_conversions ?? '-' }}</span>
                        </td>
                        <td class="font-mono">{{ ((campaign.total_cost / campaign.total_conversions_tracked) | currency: campaign.account.currency) ?? '-'}}</td>
                        <td class="font-mono">{{ (campaign.total_revenue | currency: campaign.account.currency) ?? '-' }}</td>
                        <td class="font-mono"><span gaYtGrading [percentage]="0">{{ (campaign.youtube | percent: '1.0') ?? '-%' }}</span></td>
                        <td class="font-mono">{{ ((campaign.total_clicks / campaign.total_impressions) | percent: '1.2') ?? '-%' }}</td>
                        <td class="font-mono">{{ (campaign.first_spend_date | date: "d MMM") ?? (campaign.start_date | date: "'fallback' d MM") ?? '-' }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="15" class="mv-table-empty">
                            <i class="material-symbols-outlined mv-table-empty__icon">search_off</i>
                            <div class="mv-table-empty__label">No Results</div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="groupheader" let-campaign>
                    <tr>
                        <td colspan="14" class="ga-recent-campaigns__groupheader">
                            First traffic on: {{ campaign.first_spend_date }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </mv-card>
</div>