<ga-bid *ngIf="selectedDevice" [campaignId]="campaignId" (statusChange)="reloadChanges()" [(open)]="open" [account]="account"
    [device]="selectedDevice"></ga-bid>
<mv-card [table]="true">
    <div header>
        <div class="flex items-center justify-between">
            <mv-title [size]="3">Devices</mv-title>
            <ga-sync [accountId]="account.id" type="ads"></ga-sync>
        </div>
    </div>
    <div body>
        <p-table [value]="devices">
            <ng-template pTemplate="header">
                <tr class="border-b border-gray-light">
                    <ng-container *ngFor="let range of ranges; let i = index">
                        <th *ngIf="i === 0"></th>
                        <th class="text-right border-l border-gray" colspan="5">
                            <span class="text-secondary font-bold capitalize mr-3">{{ range | rangeLabel }}</span>
                            <span class="text-secondary-light font-bold">{{ range.toString() }}</span>
                        </th>
                    </ng-container>
                </tr>
                <tr>
                    <ng-container *ngFor="let range of ranges; let i = index">
                        <th *ngIf="i === 0" class="w-96">Name</th>
                        <th class="text-right border-l border-gray">Roi</th>
                        <th [pSortableColumn]="'cost_range_' + range" class="text-right">Cost <p-sortIcon
                            [field]="'cost_range_' + range" /></th>
                        <th [pSortableColumn]="'conversions_tracked_range_' + range" class="text-right">Conv <p-sortIcon
                            [field]="'conversions_tracked_range_' + range" /></th>
                        <th class="text-right">Ad Ctr</th>
                        <th class="text-right">LCtr</th>
                      </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-device>
                <tr>
                    <td class="border-r border-gray w-96">
                        <p-inputSwitch styleClass="-my-2 mr-2" [class.red]="getDeviceStatus(device)?.value == '0'"
                            [class.yellow]="hasStatus(device) && getDeviceStatus(device)?.value != '0'"
                            [ngModel]="device.status && !hasStatus(device) || !device.status && hasStatus(device)"
                            (ngModelChange)="changeStatus(device)">
                        </p-inputSwitch>
                        <span class="font-bold mx-2 p-2 hover:bg-gray rounded-lg" pBadge severity="success" [badgeDisabled]="!hasStatus(device)"
                            [value]="(getDeviceStatus(device)?.value | percent) ?? '--%'"
                            (click)="openBidDialog(device)">{{ device.bid === 1 ? '--' : (device.bid - 1) | percent
                            }}</span>
                        {{ rangeDevices.get(ranges[0].toString())?.get(device.id)?.name }}
                    </td>

                    <ng-container *ngFor="let range of ranges; let i = index">
                        <td class="font-mono">
                            <span *ngIf="device['profit_range_' + range] / device['cost_range_' + range] ?? 0" gaGrade
                                [roi]="device['profit_range_' + range] / device['conversions_tracked_range_' + range]"
                                [conversions]="device['conversions_tracked_range_' + range]">
                                {{ ((device['profit_range_' + range] / device['cost_range_' + range]) |
                                percent: '1.0') ?? '-%' }}
                            </span>
                        </td>
                        <td class="font-mono">{{ device['cost_range_' + range] | currency:
                            account.currency:undefined:'1.0' }}</td>
                        <td class="font-mono">
                            <span class="font-bold" gaConversion
                                [mvolger]="device['conversions_tracked_range_' + range]"
                                [googleconv]="device['conversions_range_' + range]" type="mvolger">{{
                                device['conversions_tracked_range_' + range] ?? '-' }}</span>
                        </td>
                        <td class="font-mono">
                            {{ ((device['clicks_range_' + range] / device['impressions_range_' + range]) | percent: '1.2') ?? '-%' }}
                        </td>
                        <td class="font-mono border-r border-gray">
                            {{ ((device['clicks_tracked_range_' + range] / device['views_tracked_range_' + range]) | percent: '1.2') ?? '-%'}}
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </div>
</mv-card>