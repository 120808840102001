import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Url from './lib/classes/url';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @returns 
   */
  getFeatures(): Observable<Array<IFeature>> {
    let url = new Url("/features");

    return this.http.get<Array<IFeature>>(url.build());
  }

  /**
   * 
   * @param feature 
   * @returns 
   */
  toggle(feature: IFeature): Observable<IFeature> {
    let url = new Url("/features/" + feature.id + "/toggle");

    return this.http.post<IFeature>(url.build(), {});
  }

}

export interface IFeature {
  id: number;
  name: string;
  is_enabled: boolean;
}
