<p-dialog #dialog [visible]="open" (visibleChange)="toggle($event)" draggable="false" [modal]="true">
    <ng-template pTemplate="header">
        <div class="flex flex-col mr-12">
            <div>
                <mv-title [size]="3">Change Bid</mv-title>
            </div>
        </div>
    </ng-template>

    <ganal-bid-form
        #bidForm
        [accountId]="account.id"
        [campaignId]="campaignId"
        [device]="device"
        [costConv]="device.total_cost === null || device.total_conversions_tracked === null ? 0 : device.total_cost / (device.total_conversions_tracked === 0 ? 1 : device.total_conversions_tracked)">
    </ganal-bid-form>

    <div class="flex justify-between mt-12">
        <div class="flex">
            <mv-button class="mr-2" variant="primary" label="Bid" (click)="bidForm.submitForm(this)"></mv-button>
        </div>
        <div>
            <mv-button variant="secondary" (click)="close($event)" label="Cancel"></mv-button>
        </div>
    </div>
    
</p-dialog>
