import {Component, OnInit, Input} from '@angular/core';
import { IStatistics } from '../../interfaces/statistics';
import { NgIf } from '@angular/common';

@Component({
  selector: 'ganal-statistics',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  @Input() statistics!: IStatistics
  @Input() showRealRoi!: boolean;
  @Input() showCostOverlay!: boolean;

  showOverlay: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  showHideOverlay(){
    this.showOverlay = !this.showOverlay;
  }
}


