import { Component, EventEmitter, Input, Output, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/mavejs/util/notification.service';

import { StatusService } from 'src/app/status/services/status.service';
import { Calculation, Calculator } from '../../classes/bid-calculator';
import { BidModalComponent } from '../../components/legacy/bid-modal/bid-modal.component';
import { BidService } from '../../services/bid.service';
import { ModalComponent } from 'src/app/mavejs/ui/modal/modal.component';
import { TextboxComponent } from 'src/app/mavejs/form/textbox/textbox.component';
import { CurrencyPipe, NgIf, PercentPipe } from '@angular/common';
import { IDevice } from 'src/app/adgroup/interfaces/device';
import { featureIsActivated } from 'src/app/lib/classes/feature';
import { FeatureComponent } from 'src/app/feature/feature.component';
import { InputComponent } from 'src/app/lib/ui/input/input.component';
import { InputDirective } from 'src/app/lib/ui/input/input.directive';
import { ValidationComponent } from 'src/app/lib/ui/validation/validation.component';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { IStatusChangeType, IStatusHistory, IStatusPayload } from 'src/app/status/interfaces/status-change';
import { Dialog } from 'primeng/dialog';
import { BidComponent } from '../../components/bid/bid.component';
import { Adgroupv4Service } from 'src/app/adgroup/services/adgroupv4.service';
import { IAdGroup } from 'src/app/adgroup/interfaces/adgroup';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'ganal-bid-form',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    TextboxComponent,
    PercentPipe,
    FeatureComponent,
    InputComponent,
    InputDirective,
    ValidationComponent,
    CurrencyPipe
  ],
  templateUrl: './bid-form.component.html',
  styleUrls: ['./bid-form.component.scss']
})
export class BidFormComponent implements OnInit, OnChanges {
  @Input() device!: IDevice;
  @Input() accountId!: number;
  @Input() campaignId!: number;

  @Input() costConv!: number;
  @Input() costConvFormatted!: string;

  @Output() submitBid: EventEmitter<number> = new EventEmitter();
  @Output() statusChange: EventEmitter<void> = new EventEmitter();

  calculator!: Calculator;
  bidForm!: UntypedFormGroup;
  currentCalculation!: Calculation;

  constructor(
    private fb: UntypedFormBuilder, 
    private statusService: StatusService, 
    private bidService: BidService, 
    private notificationService: NotificationService, 
    private statusv4: Statusv4Service,
    private adgroupv4: Adgroupv4Service,
    private message: MessageService
  ) {

  }

  ngOnInit(): void {
    this.bidForm = this.fb.group({
      relativePercentage: [null, Validators.required],
      bidAdjustment: [null, Validators.required],
      projectedCpa: [null, Validators.required]
    });
  }

  ngOnChanges() {
    this.calculator = new Calculator(this.device.bid, parseFloat((this.costConv / 100).toFixed(3)));
  }

  get relativePercentage() {
    return this.bidForm.get('relativePercentage');
  }

  get bidAdjustment() {
    return this.bidForm.get('bidAdjustment');
  }

  get projectedCpa() {
    return this.bidForm.get('projectedCpa');
  }

  calculateRelative() {
    const value = parseFloat(this.relativePercentage?.value)

    if (this.isInvalidValue(value)) {
      return;
    }

    const calculation = this.calculator.fromRelativePercentage(value)
    this.bidAdjustment?.setValue(calculation.getFormattedBidAdjustment());
    this.projectedCpa?.setValue(calculation.getFormattedProjectedCpa());
    this.currentCalculation = calculation;
  }

  calculateBid() {
    const value = parseFloat(this.bidAdjustment?.value);

    if (this.isInvalidValue(value)) {
      return;
    }

    const calculation = this.calculator.fromBidAdjustment(value)
    this.relativePercentage?.setValue(calculation.getFormattedRelativePercentage());
    this.projectedCpa?.setValue(calculation.getFormattedProjectedCpa());
    this.currentCalculation = calculation;
  }

  calculateCpa() {
    const value = parseFloat(this.projectedCpa?.value)

    if (this.isInvalidValue(value)) {
      return;
    }

    const calculation = this.calculator.fromProjectedCpa(value)
    this.relativePercentage?.setValue(calculation.getFormattedRelativePercentage());
    this.bidAdjustment?.setValue(calculation.getFormattedBidAdjustment());
    this.currentCalculation = calculation;
  }

  isInvalidValue(value: number) {
    return (isNaN(value) || value === null)
  }


  submitForm(bidDialog: BidComponent) {
    this.adgroupv4.getAdGroupFromCampaignId(this.campaignId).subscribe((adgroup: IAdGroup) => {
      let payload: IStatusPayload = {
        id: this.device.id,
        account_id: this.accountId,
        campaign_id: this.campaignId,
        ad_group_id: adgroup.id,
        value: (this.bidForm.get('bidAdjustment')?.value / 100).toString(),
        type: IStatusChangeType.Device,
      }
  
      this.statusv4.apply(payload).subscribe({
        complete: () => { },
        error: () => this.message.add({
          severity: 'error',
          summary: 'Could not change status'
        })
      });
  
      bidDialog.toggle(false);
      bidDialog.statusChange.emit()
    })
  }

  /**
   * @deprecated Legacy submit
   * @param bidModal 
   */
  submit(bidModal: ModalComponent) {
    this.bidService.changeBid(this.accountId, this.device, (this.currentCalculation.getBidAdjustment() / 100))
    .subscribe(() => {
      bidModal.closeModal();
      this.statusService.publishChange();
    }, (error: any) => {
      this.notificationService.notify({
        id: this.device.demographics_id,
        title: "Bid adjustment invalid",
        text: error.error.errors.amount,
        type: "error"
      })
    })    
  }

  clear() {
    this.bidForm.reset()
  }

}
