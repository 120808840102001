<div>
    <ga-feature feature="campaign_statistics">
        <div default>
            <mv-card [hasHeader]="false">
                <div head>
                    Range selector
                </div>
                <div body>
                    <div class="ga-index-campaign__totals">
                        <div *ngIf="shouldRenderGraph">
                            <p-chart *ngIf="!isLoadingGraph" type="line" [data]="graphData" [options]="graphOptions" [width]="'600px'" [height]="'150px'" [plugins]="[hoverBackgroundColor]"></p-chart>
                            <form [formGroup]="chartFilterForm">
                            <div class="ga-campaign-graph__form">
                                <p-checkbox [binary]="true" label="Roi" formControlName="roi"></p-checkbox>
                                <p-checkbox [binary]="true" label="Cost" formControlName="cost"></p-checkbox>
                                <p-checkbox [binary]="true" label="CPM" formControlName="cpm"></p-checkbox>
                                <p-checkbox [binary]="true" label="AD CTR" formControlName="adctr"></p-checkbox>
                                <p-checkbox [binary]="true" label="LCTR" formControlName="lctr"></p-checkbox>
                                <p-checkbox [binary]="true" label="CVR" formControlName="cvr"></p-checkbox>
                            </div>
                            </form>
                        </div>
                        <div class="w-full pr-32">
                            <ga-totals *ngIf="isTotalsLoaded" [totals]="totals" [account]="account"></ga-totals>
                        </div>
                    </div>
                </div>
            </mv-card>
        </div>
        <div fallback>
            <div class="lg:flex lg:flex-row lg:justify-between">
                <div class="w-full">
                    <mv-card [hasHeader]="false">
                        <div body>
                            <ganal-statistics [statistics]="overallStatistics" [showRealRoi]="false"
                                [showCostOverlay]="false"></ganal-statistics>
                        </div>
                    </mv-card>
                </div>
            </div>
            <div class="lg:flex lg:flex-row lg:justify-between mt-4" *ngIf="showFilterStatistics">
                <div class="w-full rounded rounded-lg p-5 shadow shadow-xs border border-gray bg-gray-light">
                    <ganal-statistics *ngIf="!isLoadingStatistics" [statistics]="statistics" [showRealRoi]="false"
                        [showCostOverlay]="false"></ganal-statistics>
                </div>
            </div>
        </div>
    </ga-feature>

    <div class="h-4"></div>

    <div class="grid grid-cols-2 gap-4">
        <div>
            <ga-comment-overview *ngIf="account" [entityId]="account.id" [type]="0"></ga-comment-overview>
        </div>
        <div>
            <ga-change-history *ngIf="account" (highlight)="highlight($event)" (count)="trackChangeCount($event)" #changes
                [changeHistoryType]="'account'" [accountId]="accountId" [campaignId]="0">
            </ga-change-history>
        </div>
    </div>

    <div class="h-4"></div>

    <ga-cpa-dialog (submitted)="changeCpa()" [(campaign)]="selectedCampaign" [(cpa)]="newCpaValue"
        [(open)]="cpaDialogIsOpen" [account]="account">
    </ga-cpa-dialog>

    <ga-budget-dialog (submitted)="changeBudget()" [(campaign)]="selectedCampaign" [(budget)]="newBudgetValue"
        [(open)]="budgetDialogIsOpen" [account]="account">
    </ga-budget-dialog>

    <ga-comment-overview-dialog
        (submitted)="changeComment()"
        [type]="1"
        [(entity)]="selectedCommentEntity"
        [(open)]="commentDialogIsOpen">
    </ga-comment-overview-dialog>

    <mv-card *ngIf="!isLoadingAccount" [table]="true">
        <div header>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <mv-title [size]="3">Campaigns overview:</mv-title>
                    <mv-title [size]="3" *ngIf="account"><span class="text-primary ml-2">{{ account.name
                            }}</span></mv-title>
                    <div class="mr-4"></div>
                    <mv-daterangepicker (dates)="dateRangeChanged($event)" [date]="range"></mv-daterangepicker>
                </div>
                <div class="flex items-center justify-content-end">
                    <form class="flex items-center justify-content-end" [formGroup]="filterForm">
                        <div class="mr-3">
                            <mv-input>
                                <div button-left>
                                    <div class="mv-input-button">
                                        <div class="material-symbols-outlined">search</div>
                                    </div>
                                </div>
                                <input type="text" formControlName="search" placeholder="Search" class="mv-input-bare">
                            </mv-input>
                        </div>
                        <div class="mr-3">
                            <p-dropdown styleClass="mv-dropdown" optionLabel="name" optionValue="id"
                                [options]="creatorUsers" placeholder="Creator" [showClear]="true"
                                formControlName="creator"></p-dropdown>
                        </div>
                        <div class="mr-3">
                            <p-dropdown styleClass="mv-dropdown" optionLabel="name" optionValue="id"
                                [options]="uploaderUsers" placeholder="Uploader" [showClear]="true"
                                formControlName="uploader"></p-dropdown>
                        </div>
                        <div class="mr-3">
                            <mv-input>
                                <div button-left>
                                    <div class="mv-input-button">
                                        <div class="material-symbols-outlined">tag</div>
                                    </div>
                                </div>
                                <input type="text" placeholder="No." class="mv-input-bare w-14">
                            </mv-input>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div body>
            <p-table *ngIf="!campaignMetricsIsLoading" [value]="campaignMetrics" styleClass="p-datatable-striped ga-index-campaign__table">
                <ng-template pTemplate="header">
                    <tr>
                        <th [width]="10"></th>
                        <th>Name</th>
                        <th class="text-right">Budget</th>
                        <th class="text-right">Fill YDAY</th>
                        <th class="text-right">ROI</th>
                        <th class="text-right">CPA</th>
                        <th pSortableColumn="total_cost" class="text-right">Cost <p-sortIcon field="total_cost"/></th>
                        <th pSortableColumn="total_profit" class="text-right">Profit <p-sortIcon field="total_profit"/></th>
                        <th class="text-right">Conv</th>
                        <th pSortableColumn="total_revenue" class="text-right">Revenue <p-sortIcon field="total_revenue"/></th>
                        <th class="text-right">YT.COM</th>
                        <th class="text-right">AD CTR</th>
                        <th class="text-right">CPM</th>
                        <th class="text-right">LCTR</th>
                        <th class="text-right">CVR</th>
                        <th class="text-right">Last Status</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-campaign>
                    <tr [style.background-color]="(highlightedCampaign === campaign.id) ? 'rgb(190, 230, 240)' : null">
                        <td>
                            <div class="flex items-center">
                                <span class="ga-index-campaign__numbering">{{ campaignNumbering.get(campaign.id) }}</span>
                                <p-inputSwitch *ngIf="isCampaignChangesLoaded && campaign.status !== undefined"
                                    styleClass="-my-2" [class.red]="campaign.status === 2 && hasStatus(campaign)"
                                    [class.yellow]="campaign.status !== 2 && hasStatus(campaign)"
                                    [ngModel]="campaign.status === 2 && !hasStatus(campaign) || campaign.status !== 2 && hasStatus(campaign)"
                                    (ngModelChange)="changeStatus(campaign)">
                                </p-inputSwitch>
                                <p-skeleton styleClass="-my-2" *ngIf="!isCampaignChangesLoaded" height="28px" width="48px"
                                    borderRadius="50px"></p-skeleton>
                            </div>
                        </td>
                        <td>
                            <div class="flex justify-between">
                                <div class="flex items-center">
                                    <a class="flex" [routerLink]="['/ad/' + account.id + '/account/' + campaign.id + '/adgroup']">
                                        <div class="material-symbols-outlined icon-fill text-md mr-3">{{ campaign.type | campaignTypeIcon }}</div>
                                        <div class="font-bold text-secondary">{{ campaign.name ?? '-' }}</div>
                                        <div *ngIf="!campaign.is_serving_ads" class="text-red material-symbols-outlined">close
                                        </div>
                                    </a>
                                </div>
                                <div class="flex items-center">
                                    <div class="mr-6">
                                        <ga-monday [campaignId]="campaign.id" [currency]="account.currency"></ga-monday>
                                    </div>
                                    <div pBadge [badgeDisabled]="getComment(campaign.id).count === 0" [value]="getComment(campaign.id).count">
                                        <span class="ga-index-campaign-view-edit material-symbols-outlined" (click)="openCommentDialog(campaign.id)">
                                            mode_comment
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="font-mono flex items-center justify-end">
                            <span class="ga-index-campaign-view-edit material-symbols-outlined"
                                (click)="openBudgetDialog(campaign)">edit</span>
                            <span class="ga-index-campaign-view-edit-label" pBadge severity="success"
                                [badgeDisabled]="getBudgetChange(campaign.id) === 0"
                                [value]="(getBudgetChange(campaign.id) | currency) ?? '0$'">
                                {{ (campaign.budget | currency: account.currency:undefined:'1.0' ) ?? '-' }}
                            </span>
                        </td>
                        <td class="font-mono">
                            <ganal-inline-graph
                                *ngIf="isFillLoaded && fills[campaign.id] !== undefined && campaign.total_cost > 0"
                                [data]="fills[campaign.id]"
                                [formatter]="fillFormatter"
                                [formattedValue]="((fills[campaign.id][0]['y'].cost / fills[campaign.id][0]['y'].budget) | percent) ?? '-%'"
                                [painter]="fillPainter" [parser]="fillParser" dataKey="percentage">
                            </ganal-inline-graph>
                            <p-skeleton styleClass="-my-2" *ngIf="!isFillLoaded" height="21px" width="45px"
                                borderRadius="0"></p-skeleton>
                        </td>
                        <td class="font-mono">
                            <ganal-inline-graph *ngIf="isRoisLoaded && rois[campaign.id] !== undefined"
                                [data]="rois[campaign.id]"
                                [formatter]="roiFormatter"
                                [formattedValue]="(campaign.total_profit / campaign.total_cost | percent) ?? '-%'"
                                [painter]="roiPainter" [parser]="roiParser">
                            </ganal-inline-graph>
                            <p-skeleton styleClass="-my-2" *ngIf="!isRoisLoaded" height="21px" width="45px"
                                borderRadius="0"></p-skeleton>
                        </td>
                        <td class="font-mono flex items-center justify-end">
                            <span class="ga-index-campaign-view-edit material-symbols-outlined"
                                (click)="openCpaDialog(campaign)">edit</span>
                            <span class="ga-index-campaign-view-edit-label"
                                *ngIf="!hasMaxConversionsChange(campaign.id)" pBadge severity="success"
                                [badgeDisabled]="getCpaChange(campaign.id) === 0"
                                [value]="(getCpaChange(campaign.id) | currency) ?? '0$'">
                                {{ (campaign.target_cpa | currency: account.currency) ?? '-' }}
                            </span>
                            <span *ngIf="hasMaxConversionsChange(campaign.id)" class="ga-index-campaign-view-edit-label"
                                pBadge severity="success" [badgeDisabled]="!hasMaxConversionsChange(campaign.id)"
                                value="MAX">
                                {{ (campaign.target_cpa | currency: account.currency) ?? '-' }}
                            </span>
                        </td>
                        <td class="font-mono">{{ (campaign.total_cost | currency: account.currency:undefined:'1.0-0') ??
                            '-' }}</td>
                        <td class="font-mono">{{ ((campaign.total_revenue - campaign.total_cost) | currency: account.currency:undefined:'1.0-0') ??
                            '-' }}</td>
                        <td class="font-mono">
                            <ga-conv [entity]="campaign"></ga-conv>
                        </td>
                        <td class="font-mono">{{ (campaign.total_revenue | currency: account.currency:undefined:'1.0-0') ?? '-' }}</td>
                        <td class="font-mono"><span gaYtGrading [percentage]="0">{{ (campaign.youtube | percent: '1.0') ?? '-%' }}</span></td>
                        <td class="font-mono">{{ ((campaign.total_clicks / campaign.total_impressions) | percent: '1.2') ?? '-%' }}</td>
                        <td class="font-mono">{{ ((campaign.total_cost / (campaign.total_impressions / 1000)) | currency: account.currency:undefined:'1.0') ?? '-%'}}</td>
                        <td class="font-mono">{{ ((campaign.total_clicks_tracked / campaign.total_views_tracked) | percent) ?? '-%'}}</td>
                        <td class="font-mono">{{ ((campaign.total_conversions_tracked / campaign.total_clicks_tracked) | percent) ?? '-%'  }}</td>
                        <td class="font-mono">{{ (campaign.start_date | date) ?? '-' }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="15" class="mv-table-empty">
                            <i class="material-symbols-outlined mv-table-empty__icon">search_off</i>
                            <div class="mv-table-empty__label">No Results</div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div footer>
            <ga-sync [accountId]="account.id" type="campaigns"></ga-sync>
        </div>
    </mv-card>
</div>
