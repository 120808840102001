<ga-feature feature="bid">
  <div default>
    <form [formGroup]="bidForm">
      <div class="flex justify-between mb-4">
        <div class="px-2 text-left">
          <div class="text-xs font-bold text-gray-dark">Cost/Conv ratio</div>
          <span class="text-secondary font-bold">{{ costConv | currency }}</span>
        </div>
        <div class="px-2 text-right">
          <div class="text-xs font-bold text-gray-dark">Current Bid Adjustment</div>
          {{ device.bid === 1 ? '--' : (device.bid - 1) | percent}}
        </div>
      </div>
      <div class="flex w-full">
        <div class="px-2 w-1/2">
          <mv-input label="Rel %">
            <input mvInput type="text" formControlName="relativePercentage" (keyup)="calculateRelative()">
            <mv-validation [control]="relativePercentage" error="required" message="Please enter relative percentage"></mv-validation>
          </mv-input>
        </div>
        <div class="px-2 w-1/2">
          <mv-input label="Bid Adj.">
            <input mvInput type="text" formControlName="bidAdjustment" (keyup)="calculateBid()">
            <mv-validation [control]="bidAdjustment" error="required" message="Please enter bid adjustment"></mv-validation>
          </mv-input>
          <div class="-mt-3">
            <div *ngIf="(relativePercentage?.value < -100)" class="flex items-center text-red text-xs font-bold">
              <div class="mr-1"><i class="material-symbols-outlined text-sm">warning</i></div>
              <div>Bid is too low</div>
            </div>
            <div *ngIf="(relativePercentage?.value > 100)" class="flex items-center text-yellow-dark text-xs font-bold">
              <div class="mr-1"><i class="material-symbols-outlined text-sm">warning</i></div>
              <div>Bid is very high</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="px-2 w-1/2">
          <mv-input label="Projected CPA">
            <input mvInput type="text" formControlName="projectedCpa" (keyup)="calculateCpa()">
            <mv-validation [control]="projectedCpa" error="required" message="Please enter projected CPA"></mv-validation>
          </mv-input>
        </div>
        <div class="px-2"></div>
      </div>
    </form>
  </div>
  <div fallback>
    <form [formGroup]="bidForm">
      <div class="flex justify-between mb-4">
        <div class="px-2 text-left">
          <div class="text-xs font-bold text-gray-dark">Cost/Conv ratio</div>
          <span class="text-secondary font-bold">{{ costConvFormatted }}</span>
        </div>
        <div class="px-2 text-right">
          <div class="text-xs font-bold text-gray-dark">Current Bid Adjustment</div>
          {{ device.bid === 1 ? '--' : (device.bid - 1) | percent}}
        </div>
      </div>
      <div class="flex w-full">
        <div class="px-2 w-1/2">
          <mave-textbox label="Rel %">
            <input type="text" formControlName="relativePercentage" (keyup)="calculateRelative()" maveInput />
          </mave-textbox>
        </div>
        <div class="px-2 w-1/2">
          <mave-textbox label="Bid Adj.">
            <input type="text" formControlName="bidAdjustment" (keyup)="calculateBid()" maveInput />
          </mave-textbox>
          <div class="-mt-3">
            <div *ngIf="(relativePercentage?.value < -100)" class="flex items-center text-red text-xs font-bold">
              <div class="mr-1"><i class="material-symbols-outlined text-sm">warning</i></div>
              <div>Bid is too low</div>
            </div>
            <div *ngIf="(relativePercentage?.value > 100)" class="flex items-center text-yellow-dark text-xs font-bold">
              <div class="mr-1"><i class="material-symbols-outlined text-sm">warning</i></div>
              <div>Bid is very high</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="px-2 w-1/2">
          <mave-textbox label="Projected CPA">
            <input type="text" formControlName="projectedCpa" (keyup)="calculateCpa()" maveInput />
          </mave-textbox>
        </div>
        <div class="px-2"></div>
      </div>
    </form>
  </div>
</ga-feature>


