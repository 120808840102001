import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ITotals } from '../../interfaces/statistics';
import { CurrencyPipe, JsonPipe, NgIf, PercentPipe } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Accountv4Service } from 'src/app/account/services/accountv4.service';
import { Account, IAccount } from 'src/app/account/interfaces/account';
import { DateRange } from '../../classes/daterange';
import { ICostCurrencies } from 'src/app/campaign/interfaces/campaign';

@Component({
  selector: 'ga-totals',
  standalone: true,
  imports: [
    NgIf,
    CurrencyPipe,
    PercentPipe,
    SkeletonModule,
    OverlayPanelModule
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './totals.component.html',
  styleUrl: './totals.component.css'
})
export class TotalsComponent {
  @ViewChild('costPanel') costPanel!: OverlayPanel;

  @Input() totals!: ITotals;
  @Input() account: IAccount = Account.noop();
  @Input() range!: DateRange;

  cost!: ICostCurrencies | undefined;

  constructor(private accountv4: Accountv4Service) {}

  openCost($event: Event) {
    this.costPanel.toggle($event)
    this.cost = undefined;
    this.accountv4.currencies(this.range).subscribe((cost: ICostCurrencies) => {
      this.cost = cost;
    })
  }

}
