import { Pipe, PipeTransform } from '@angular/core';
import { Ranges } from 'src/app/lib/classes/ranges';

@Pipe({
  name: 'rangeLabel',
  standalone: true
})
export class RangeLabelPipe implements PipeTransform {

  transform(range: any, ...args: unknown[]): unknown {
    let selectedRange = new Ranges().rangesReversed[range.toString()]

    if (selectedRange === undefined) {
      return 'Custom'
    } else {
      return selectedRange.toLowerCase().replaceAll("_", " ")
    }
  }

}
