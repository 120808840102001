import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GraphFormatterBuilder } from './graph-formatter-builder';
import { IAccount } from 'src/app/account/interfaces/account';
import { enUS } from 'date-fns/locale';
import { IGraphFormatter } from './formatters/igraph-formatter';

export class GraphOptions {

  public static getHoverBackgroundColor(): any {
    return {
      id: 'hoverBackgroundColor',
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        const activePoints = chart.tooltip._active;
        if (activePoints && activePoints.length) {
          const { x } = activePoints[0].element;
          const chartArea = chart.chartArea;

          // Draw a vertical line
          ctx.save();
          ctx.strokeStyle = '#E2EEF2';
          ctx.lineWidth = 15;
          ctx.beginPath();
          ctx.moveTo(x, chartArea.top);
          ctx.lineTo(x, chartArea.bottom);
          ctx.stroke();
          ctx.restore();
        }
      }
    }
  }

  public static getAccountGraphOptions(hasLabels: boolean, account: IAccount): any {
    return {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 30,
          bottom: 0
        }
      },
      plugins: {
        ChartDataLabels,
        legend: {
          display: false,
          font: {
            family: 'Inter'
          }
        },
        tooltip: {
          enabled: true,
          font: {
            family: 'Inter'
          },
          mode: 'index',
          intersect: false,
          titleFont: { size: 14 },
          bodyFont: { size: 14, weight: 'bold' },
          padding: 8,
          bodySpacing: 2,
          xAlign: 'center',
          yAlign: 'top',
          callbacks: {
            label: function (context: any) {
              return GraphFormatterBuilder.get(context.parsed.y, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format();
            }
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          textAlign: 'center',
          offset: '-2',
          color: 'black',
          display: hasLabels,
          font: {
            family: 'Inter'
          },
          formatter: function(value: any, context: any) {
            return GraphFormatterBuilder.get(value, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format(); // '\n€' + value.y.cost + '\n' + value.y.budget;
          }
        }
      },
      cubicInterpolationMode: 'monotone',
      spanGaps: true,
      scales: {
        x: {
          type: 'time',
          time: {
            displayFormats: {
              day: 'eee d LLL'
            },
            unit: 'day'
          },
        },
        y: {
          display: false,
          beginAtZero: false,
          min: -20
        }
      }
    };
  }

  public static getCampaignGraphOptions(hasLabels: boolean, account: IAccount): any {
    return {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 30,
          bottom: 0
        }
      },
      plugins: {
        // ChartDataLabels,
        legend: {
          display: false,
          font: {
            family: 'Inter'
          }
        },
        tooltip: {
          enabled: true,
          font: {
            family: 'Inter'
          },
          mode: 'index',
          intersect: false,
          titleFont: { size: 14 },
          bodyFont: { size: 14, weight: 'bold' },
          padding: 8,
          bodySpacing: 2,
          xAlign: 'center',
          yAlign: 'top',
          callbacks: {
            label: function (context: any) {
              return GraphFormatterBuilder.get(context.parsed.y, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format();
            }
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          textAlign: 'center',
          offset: '-2',
          color: 'black',
          display: hasLabels,
          font: {
            family: 'Inter'
          },
          formatter: function(value: any, context: any) {
            return GraphFormatterBuilder.get(value, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format(); // '\n€' + value.y.cost + '\n' + value.y.budget;
          }
        }
      },
      cubicInterpolationMode: 'monotone',
      spanGaps: true,
      scales: {
        x: {
          type: 'time',
          time: {
            displayFormats: {
              day: 'eee d LLL'
            },
            unit: 'day'
          },
          date: {
            locale: enUS
          }
        },
        y: {
          display: false,
          beginAtZero: false,
          min: -2000000000
        }
      }
    };
  }

  public static getBigGraphOptions(account: IAccount, formatter: IGraphFormatter): any {
    let bigGraph = {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 30,
          bottom: 0
        }
      },
      plugins: {
        ChartDataLabels,
        legend: {
          display: false,
          font: {
            family: 'Inter'
          }
        },
        tooltip: {
          enabled: false,
          font: {
            family: 'Inter'
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          textAlign: 'center',
          offset: '-2',
          color: 'black',
          font: {
            family: 'Inter'
          },
          formatter: function(value: any, context: any) {
            return GraphFormatterBuilder.get(value, context.dataset.rawData[context.dataIndex], context.dataset.parser, account).format(); // '\n€' + value.y.cost + '\n' + value.y.budget;
          }
        }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day'
          },
          date: {
            locale: enUS
          }
        },
        y: {
          display: false,
          beginAtZero: true,
          max: formatter.max(),
          min: formatter.min()
        }
      }
    };

    return bigGraph;
  }

  public static getSmallGraphOptions(formatter: IGraphFormatter): any {
    let smallGraph = {
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        },
        datalabels: {
          formatter: function(value: any, context: any) {
            return '';
          }
        }
      },
      parsing: {},
      scales: {
        x: {
          display: false,
          type: 'time',
          time: {
            unit: 'day'
          },
          date: {
            locale: enUS
          }
        },
        y: {
          display: false,
          beginAtZero: true,
          max: formatter.max(),
          min: formatter.min()
        }
      }
    };

    return smallGraph;
  }
}
