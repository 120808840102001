/**
 * @deprecated use ITotals
 */
export interface IStatistics {
    conversions: number;
    conversionsTracked: number;
    clicks: number;
    cost: string;
    costConv: string;
    reliability: string;
    roi: string;
    realROI: string;
    costEur: string;
    costUsd: string;
}

export interface ITotals {
  total_clicks: number
  total_cost: number
  total_profit: number
  total_unknowns: number
  total_conversions: number
  total_conversions_tracked: number
  rate: number
}

export class Totals {
  static noop(): ITotals {
    return {
      'total_clicks': 0,
      'total_cost': 0,
      'total_profit': 0,
      'total_unknowns': 0,
      'total_conversions': 0,
      'total_conversions_tracked': 0,
      'rate': 0
    }
  }
}