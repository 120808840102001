<p-dialog #dialog [visible]="open" (visibleChange)="toggle($event)" draggable="false" [modal]="true">
    <ng-template pTemplate="header">
        <div class="flex flex-col mr-12">
            <div>
                <mv-title [size]="3">Change Cpa</mv-title>
            </div>
            <div class="text-sm">{{ campaign ? campaign.name : '-' }}</div>
            <div class="text-sm text-primary-light">{{ account ? account.name : '-' }}</div>
        </div>
    </ng-template>
    
    <mv-input label="Cpa">
        <div button>
            <div *ngIf="account !== undefined" class="p-1 pr-3 text-secondary font-bold">
                {{ account.currency === 'EUR' ? '€' : '$' }}
            </div>
            
        </div>
        <input class="mv-input-bare" [(ngModel)]="cpa" type="text">
    </mv-input>

    <div class="flex justify-between mt-12">
        <div class="flex">
            <mv-button class="mr-2" variant="primary" label="Change Cpa" (click)="changeCpa()"></mv-button>
            <mv-button variant="secondary" label="Set Max Conversions" (click)="changeMaxConversions()"></mv-button>
        </div>
        <div>
            <mv-button variant="secondary" (click)="close($event)" label="Cancel"></mv-button>
        </div>
    </div>
</p-dialog>