import { Component, Input } from '@angular/core';
import { ConversionDirective } from '../../directives/conversion.directive';
import { IWithConversions } from 'src/app/account/interfaces/account-metrics-result';

@Component({
  selector: 'ga-conv',
  standalone: true,
  imports: [
    ConversionDirective
  ],
  templateUrl: './conv.component.html',
  styleUrl: './conv.component.css'
})
export class ConvComponent {
  @Input() entity!: IWithConversions
}