import { CommonModule, NgClass, NgIf } from '@angular/common';
import { Component, Input, ElementRef, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'mv-button',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.css',
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {

  @Input() label!: string;
  @Input() variant!: string;
  @Input() icon!: string;
  @Input() type!: string;
  @Input() loading: boolean = false;

  @Output("click") click = new EventEmitter();

  classes: string = "mv-button ";

  ngOnInit() {
    // switch(this.variant) {
    //   case 'primary':
    //     this.classes += 'mv-button-primary';
    //     break;
    //   case 'danger':
    //     this.classes += 'mv-button-danger';
    //     break;
    //   case 'warning':
    //     this.classes += 'px-5 py-3 bg-yellow-light text-yellow-dark hover:bg-yellow hover:text-yellow-dark';
    //     break;
    //   case 'secondary':
    //     this.classes += 'mv-button-secondary';
    //     break;
    //   case 'default-dark':
    //     this.classes += 'px-5 py-3 hover:bg-gray-light hover:bg-opacity-20 text-white';
    //     break;
    //   case 'ghost':
    //     this.classes += 'mv-button-ghost';
    //     break;
    //   default:
    //     this.classes += 'mv-button-secondary'
    // }


    let variants: any = {
      'primary': 'mv-button-primary',
      'secondary': 'mv-button-secondary',
      'danger': 'mv-button-danger',
      'danger-transparent': 'mv-button-danger-transparent',
      'positive': 'mv-button-positive',
      'positive-transparent': 'mv-button-positive-transparent',
      'warning': 'mv-button-warning'
    }

    this.classes += variants[this.variant]

  }

}
