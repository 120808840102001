import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpStatusCode
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/mavejs/util/notification.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(private router: Router, private note: NotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: any) => {
        if (req.method === "OPTIONS") {
          return next.handle(req);
        }

        if (error.status === HttpStatusCode.Unauthorized) {
          this.note.notify({
            "title": "Unauthorized access",
            "text": "This action is not permitted. You don't have any rights",
            "type": "unauthorized"
          })
        }

        if (error.status === HttpStatusCode.InternalServerError) {
          this.note.notify({
            "title": "Something unexpected happened",
            "text": "An error has occured please report this to the web administrator",
            "type": "unauthorized"
          })
        }

        return throwError(error);
      })
    )
  }
}
