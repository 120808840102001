<div class="mv-card">
  <div class="mv-card-header" *ngIf="hasHeader">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="mv-card-body" [ngClass]="{'mv-card-body-table': table, 'mv-card-body-noheader-table': (table && !hasHeader)}">
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="mv-card-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
