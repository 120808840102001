import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Dialog, DialogModule } from 'primeng/dialog';
import { IAccount } from 'src/app/account/interfaces/account';
import { InputComponent } from 'src/app/lib/ui/input/input.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { ICampaign, ICampaignDetail } from '../../interfaces/campaign';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { CurrencyPipe, NgIf } from '@angular/common';
import { IStatusChangeType } from 'src/app/status/interfaces/status-change';

@Component({
  selector: 'ga-budget-dialog',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    DialogModule,
    InputComponent,
    TitleComponent,
    ButtonComponent,
    CurrencyPipe
  ],
  templateUrl: './budget-dialog.component.html',
  styleUrl: './budget-dialog.component.css'
})
export class BudgetDialogComponent implements AfterViewInit {
  @ViewChild('dialog') dialog!: Dialog;

  @Input() account!: IAccount;
  @Input() campaign!: ICampaignDetail;
  @Input() open!: boolean;
  @Input() budget!: number;

  @Output() openChange = new EventEmitter<boolean>();
  @Output() campaignChange = new EventEmitter<ICampaignDetail>();
  @Output() budgetChange = new EventEmitter<string>();
  @Output() submitted = new EventEmitter<void>();

  constructor(private statusv4: Statusv4Service) {}

  ngAfterViewInit(): void {
    this.dialog.visibleChange.subscribe(() => {
      this.openChange.emit(false);
    })
  }

  close(event: Event) {
    this.dialog.close(event);
  }

  toggle(isOpen: boolean) {
    this.openChange.emit(isOpen);
  }

  changeBudget() {
    this.statusv4.apply({
      id: this.campaign.id,
      account_id: this.account.id,
      campaign_id: this.campaign.id,
      ad_group_id: 0,
      type: IStatusChangeType.Budget,
      value: this.budget.toString(),
    }).subscribe(() => {
      this.openChange.emit(false);
      this.submitted.emit();
    })
  }

}
