import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'mv-validation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './validation.component.html',
  styleUrl: './validation.component.scss'
})
export class ValidationComponent {

  @Input() message!: string;
  @Input() control!: AbstractControl | null;
  @Input() error!: string;


  get processedMessage() {
    let processedMessage = this.message;

    if (this.error === 'minlength') {
      processedMessage = processedMessage.replace('[requiredLength]', this.getPropertiesBy('minlength').requiredLength);
      processedMessage = processedMessage.replace('[actualLength]', this.getPropertiesBy('minlength').actualLength);
    }

    if (this.error === 'maxlength') {
      processedMessage = processedMessage.replace('[requiredLength]', this.getPropertiesBy('maxlength').requiredLength);
      processedMessage = processedMessage.replace('[actualLength]', this.getPropertiesBy('maxlength').actualLength);
    }

    if (this.error === 'video') {
      processedMessage = processedMessage.replace('[video]', this.getPropertiesBy('video'));
    }

    return processedMessage;
  }

  /**
   *
   * @param errors
   * @param key
   */
  getProperties(errors: any, key: string) {
    return errors[key];
  }

  /**
   *
   * @param key
   * @returns
   */
  getPropertiesBy(key: string) {
    return this.getProperties(this.control?.errors, key);
  }



}
