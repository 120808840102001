export interface IAge {
    id: number;
    name: string;
    status: number;
    total_cost: number;
    total_clicks: number;
    total_views: number;
    total_impressions: number;
    total_revenue: number;
    total_profit: number;
    total_conversions: number;
    total_conversions_tracked: number;
}

export interface IParental {
    id: number;
    name: string;
    status: number;
    total_cost: number;
    total_clicks: number;
    total_views: number;
    total_impressions: number;
    total_revenue: number;
    total_profit: number;
    total_conversions: number;
    total_conversions_tracked: number;   
}

export interface IGender {
    id: number;
    name: string;
    status: number;
    total_cost: number;
    total_clicks: number;
    total_views: number;
    total_impressions: number;
    total_revenue: number;
    total_profit: number;
    total_conversions: number;
    total_conversions_tracked: number;   
}

export interface IIncome {
    id: number;
    name: string;
    status: number;
    total_cost: number;
    total_clicks: number;
    total_views: number;
    total_impressions: number;
    total_revenue: number;
    total_profit: number;
    total_conversions: number;
    total_conversions_tracked: number;       
}

/**
 * 
 * @param campaignRevenue 
 * @param conversions 
 * @param cost 
 * @param costConv 
 * @returns 
 */
export function estimateRoi(campaignRevenue: number, cost: number, campaignConversions: number, conversions: number): number | null {
    let costConv = (cost / conversions);

    if (conversions == 0 && cost > 0) {
        return -1;
    }

    if (costConv ==  0.0 && cost > 0.0) {
        return -1;
    }
    
    if (campaignRevenue == 0.0 || conversions == 0.0 || costConv == 0.0) {
        return null;
    }

    let revenueConversion = (campaignRevenue / campaignConversions);

    return ((revenueConversion / costConv) - 1);
}

/**
 * 
 * @param campaignCost 
 * @param cost 
 * @returns 
 */
export function costDistribution(campaignCost: number, cost: number) {
    if (cost === 0) {
        return 0;
    }

    return cost / campaignCost;
}