export class OperationType {
  type = '';

  constructor(type: string) {
    this.type = type;
  }

  valueTypes = ['CPA', 'BID', 'BUDGET'];

  isValue() {
    return this.valueTypes.includes(this.type);
  }

}
