<mv-card [table]="true">
    <div header>
        <div class="flex items-center justify-between">
            <mv-title [size]="3">Incomes</mv-title>
            <ga-sync [accountId]="account.id" type="incomes"></ga-sync>
        </div>
    </div>
    <div body>
        <p-table [value]="incomes">
            <ng-template pTemplate="header">
                <tr class="border-b border-gray-light">
                    <th></th>
                    <th class="text-right border-l border-gray" colspan="4" *ngFor="let range of ranges">
                        <span class="text-secondary font-bold capitalize mr-3">{{ range | rangeLabel }}</span>
                        <span class="text-secondary-light font-bold">{{ range.toString() }}</span>
                    </th>
                </tr>

                <tr>
                    <th class="border-r border-gray w-96">Name</th>

                    <ng-container *ngFor="let range of ranges">
                        <th class="text-right">~ROI</th>
                        <th class="text-right">Cost</th>
                        <th class="text-right">Conv</th>
                        <th class="text-right border-r border-gray">CTR</th>
                    </ng-container>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-income>
                <tr>
        
                    <td class="border-r border-gray w-96">
                        <p-inputSwitch
                            styleClass="-my-2 mr-2" 
                            [class.red]="income.status && hasStatus(income)"
                            [class.yellow]="!income.status && hasStatus(income)"
                            [ngModel]="income.status && !hasStatus(income) || !income.status && hasStatus(income)" 
                            (ngModelChange)="changeStatus(income)">
                        </p-inputSwitch>

                        {{ rangeIncomes.get(ranges[0].toString())?.get(income.id)?.name }}

                        <span class="text-gray-dark float-right">{{ costDistribution(ranges[0].toString(), rangeIncomes.get(ranges[0].toString())?.get(income.id)?.total_cost ?? 0) | percent:'1.0-0' }}</span>
                    </td>
    
                    <ng-container *ngFor="let range of ranges">
                        <td class="font-mono border-l border-gray">
                            <span 
                            *ngIf="(rangeIncomes.get(range.toString())?.get(income.id)?.total_cost ?? 0)"
                            gaGrade 
                            [roi]="estimateRoi(range.toString(), (rangeIncomes.get(range.toString())?.get(income.id)?.total_cost ?? 0), (rangeIncomes.get(range.toString())?.get(income.id)?.total_conversions ?? 0))"
                            [conversions]="rangeIncomes.get(range.toString())?.get(income.id)?.total_conversions_tracked ?? 0"
                            >
                            {{ (estimateRoi(range.toString(), (rangeIncomes.get(range.toString())?.get(income.id)?.total_cost ?? 0), (rangeIncomes.get(range.toString())?.get(income.id)?.total_conversions ?? 0)) | percent: '1.0-0') ?? '-%' }}
                            </span>
                        </td>
                        <td class="font-mono">
                            {{ (rangeIncomes.get(range.toString())?.get(income.id)?.total_cost | currency: account.currency:undefined:'1.0') ?? '-'}}
                        </td>
                        <td class="font-mono">
                            <span class="font-bold">{{ (rangeIncomes.get(range.toString())?.get(income.id)?.total_conversions | number:'1.0-0') ?? '-' }}</span>
                        </td>
                        <td class="font-mono">
                            {{ ((((rangeIncomes.get(range.toString())?.get(income.id)?.total_clicks ?? 0) / (rangeIncomes.get(range.toString())?.get(income.id)?.total_impressions ?? 0))) | percent: '1.2') ?? '-%' }}
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </div>
</mv-card>