import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mave-menu-split',
  standalone: true,
  templateUrl: './menu-split.component.html',
  styleUrls: ['./menu-split.component.css']
})
export class MenuSplitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
