import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { IAccount } from 'src/app/account/interfaces/account';
import { ICampaign } from 'src/app/campaign/interfaces/campaign';
import { IDevice } from 'src/app/adgroup/interfaces/device';


@Injectable({
  providedIn: 'root'
})
export class BidService {

  constructor(private backend: BackendService) { }

  public changeBid(accountId: number, device: IDevice, bid: number) {
    return this.backend.sendPost("/api/v1/status/device/" + device.demographics_id + "/bid?tenant=" + accountId, { amount: bid });
  }
}
