import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, range } from 'rxjs';
import { DateRange } from 'src/app/lib/classes/daterange';
import Url from 'src/app/lib/classes/url';
import { IBudgetMapping, ICampaign, ICampaignData, ICampaignDetail, ICampaignMetrics, ICampaignMonday, ICampaignPagination, IMeanBudget, IRoiMapping } from '../interfaces/campaign';
import { ITotals } from 'src/app/lib/interfaces/statistics';
import { ICampaignPaginator } from '../interfaces/campaign-paginator';

@Injectable({
  providedIn: 'root'
})
export class Campaignv4Service {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @param accountId 
   * @param range 
   * @returns 
   */
  getWithMetrics(accountId: number, search: string, creatorId: number, uploaderId: number, range: DateRange): Observable<Array<ICampaignMetrics>> {
    let url = new Url("/campaign/by_account/" + accountId)
      .query('search', search)
      .query('creator_id', creatorId)
      .query('uploader_id', uploaderId)
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted());

    return this.http.get<Array<ICampaignMetrics>>(url.build());
  }

  /**
   * 
   * @param accountId 
   * @returns 
   */
  getRois(accountId: number): Observable<IRoiMapping> {
    let url = new Url("/campaign/by_account/" + accountId + "/rois");

    return this.http.get<IRoiMapping>(url.build())
  }

  /**
   * 
   * @param accountId 
   * @returns 
   */
  getBudgets(accountId: number): Observable<IBudgetMapping> {
    let url = new Url("/campaign/by_account/" + accountId + "/budgets");

    return this.http.get<IBudgetMapping>(url.build());
  }

  /**
   * 
   * @param accountId 
   * @param campaignId 
   * @param ranges 
   * @returns 
   */
  getByIdByRanges(accountId: number, campaignId: number, ranges: Array<DateRange>): Observable<Array<ICampaignData>> {
    let url = new Url("/campaign/by_account/" + accountId + "/campaign/" + campaignId + "/by_ranges");

    for (let range of ranges) {
      url.query("from", range.getFromFormatted())
      url.query("to", range.getToFormatted())
    }

    return this.http.get<Array<ICampaignData>>(url.build())
  }

  /**
   * 
   * @param campaignId 
   * @returns 
   */
  getCampaign(campaignId: number): Observable<ICampaignDetail> {
    let url = new Url("/campaign/" + campaignId);

    return this.http.get<ICampaignDetail>(url.build());
  }

  /**
   * 
   * @param range 
   * @returns 
   */
  getTotals(accountId: number, search: string, creatorId: number, uploaderId: number, range: DateRange): Observable<ITotals> {
    let url = new Url("/campaign/" + accountId + "/totals")
      .query('search', search)
      .query('creator_id', creatorId)
      .query('uploader_id', uploaderId)
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted());

    return this.http.get<ITotals>(url.build());
  }

  /**
   * 
   * @param range 
   * @returns 
   */
  getRecent(search: string, creatorId: number, uploaderId: number, range: DateRange): Observable<Array<ICampaignMetrics>> {
    let url = new Url("/campaign/recent")
      .query('search', search)
      .query('creator_id', creatorId)
      .query('uploader_id', uploaderId)
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted());

    return this.http.get<Array<ICampaignMetrics>>(url.build());
  }

  /**
   * 
   * @param range 
   * @returns 
   */
  getRecentTotals(search: string, creatorId: number, uploaderId: number, range: DateRange): Observable<ITotals> {
    let url = new Url("/campaign/recent/totals")
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted())
      .query('search', search)
      .query('creator_id', creatorId)
      .query('uploader_id', uploaderId);

    return this.http.get<ITotals>(url.build());
  }

  /**
   * 
   * @param campaignId 
   * @returns 
   */
  getMeanBudget(campaignId: number): Observable<IMeanBudget> {
    let url = new Url("/campaign/" + campaignId + "/mean_budget")

    return this.http.get<IMeanBudget>(url.build());
  }

  /**
   * 
   * @param range 
   * @returns 
   */
  getGraph(accountId: number, search: string, creatorId: number, uploaderId: number, range: DateRange): Observable<any> {
    let url = new Url("/campaign/" + accountId + "/graph")
      .query('search', search)
      .query('creator_id', creatorId)
      .query('uploader_id', uploaderId)
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted());

    return this.http.get<any>(url.build());
  }

  /**
   * 
   * @param accountId 
   * @param campaignId 
   * @returns 
   */
  paginate(accountId: number, campaignId: number, isRunning: boolean): Observable<ICampaignPagination> {
    let url = new Url("/campaign/by_account/" + accountId + "/campaign/" + campaignId + "/paginate")
      .query("is_running", isRunning);

    return this.http.get<ICampaignPagination>(url.build());
  }

  /**
   * 
   * @param campaignId 
   * @returns 
   */
  monday(campaignId: number): Observable<ICampaignMonday> {
    let url = new Url("/campaign/" + campaignId + "/monday");

    return this.http.get<ICampaignMonday>(url.build());
  }

}