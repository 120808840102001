import { Pipe, PipeTransform } from '@angular/core';
import { Shorten } from '../classes/shorten';

@Pipe({
  name: 'adName',
  standalone: true
})
export class AdNamePipe implements PipeTransform {

  transform(text?: string): string {
    return Shorten.ad(text ?? '');
  }

}
