import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mv-title',
  standalone: true,
  imports: [],
  templateUrl: './title.component.html',
  styleUrl: './title.component.css'
})
export class TitleComponent {

  @Input() size: number = 0;
  @Input() variant: string = 'primary'
  

}
