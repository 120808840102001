export class StatusType {

  type = '';
  demographics = ['Age', 'Parental', 'Gender', 'Income']

  constructor(type: string) {
    this.type = type;
  }

  render() {
    if (this.demographics.includes(this.type)) {
      return 'Demographics'
    }

    return this.type;
  }

}
