import {Component, Input, Output, EventEmitter, OnInit, ViewChild} from '@angular/core';
import { IRange, Ranges } from '../../classes/ranges';
import {DemographicsTableComponent} from "../../../ad/components/legacy/demographics-table/demographics-table.component";
import {RangeStatisticsComponent} from "../../../ad/components/legacy/range-statistics/range-statistics.component";
import {AdWorkbarComponent} from "../../../ad/components/legacy/ad-workbar/ad-workbar.component";
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../user/services/user.service";
import {TemplateService} from "../../../template/services/template.service";
import { NotificationService } from 'src/app/mavejs/util/notification.service';
import { ModalComponent } from 'src/app/mavejs/ui/modal/modal.component';
import { SummaryPipe } from 'src/app/mavejs/pipes/summary/summary.pipe';
import { ButtonComponent } from 'src/app/mavejs/form/button/button.component';
import { ActionComponent } from 'src/app/mavejs/base/action/action.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { SubTitleComponent } from 'src/app/mavejs/text/sub-title/sub-title.component';
import { CardComponent } from 'src/app/mavejs/base/card/card.component';
import { TextboxComponent } from 'src/app/mavejs/form/textbox/textbox.component';
import { SummariesPipe } from '../../pipes/summaries.pipe';
import { ITemplate, ITemplateLegacy, ITemplateRange, User } from 'src/app/template/classes/template';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { IUser } from 'src/app/auth/classes/user';
import { Templatev4Service } from 'src/app/template/services/templatev4.service';
import { DateRange } from '../../classes/daterange';

/**
 * @deprecated Should be removed in favor of new selector
 */
@Component({
  selector: 'ganal-rangeselect-multi',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    ReactiveFormsModule,
    ButtonComponent,
    ActionComponent,
    SummaryPipe,
    SummariesPipe,
    SubTitleComponent,
    CardComponent,
    TextboxComponent,
    ModalComponent
  ],
  templateUrl: './rangeselect-multi.component.html',
  styleUrls: ['./rangeselect-multi.component.scss']
})
export class RangeselectMultiComponent implements OnInit {

  @Input() ranges!: Array<DateRange>;
  @Output() rangesChange: EventEmitter<Array<number>> = new EventEmitter();

  @ViewChild("templateModal") templateModal!: ModalComponent;
  templates?: Array<ITemplate>;
  templateForm!: UntypedFormGroup;
  templateIsSaving = false;
  selectedTemplate!: ITemplate;

  @ViewChild("rangesSelector") rangesSelector!: RangeselectMultiComponent;
  @ViewChild("adTable") adTable!: DemographicsTableComponent;
  @ViewChild("deviceTable") deviceTable!: DemographicsTableComponent;
  @ViewChild("ageTable") ageTable!: DemographicsTableComponent;
  @ViewChild("genderTable") genderTable!: DemographicsTableComponent;
  @ViewChild("parentalTable") parentalTable!: DemographicsTableComponent;
  @ViewChild("incomeTable") incomeTable!: DemographicsTableComponent;
  @ViewChild("statistics") statistics!: RangeStatisticsComponent;
  @ViewChild("adWorkBar") adWorkBar!: AdWorkbarComponent;

  selectedRanges: Array<number> = [];
  allRanges!: Array<IRange>;

  isChangingRanges: boolean = false;
  showMenu: boolean = false;
  showTemplateMenu: boolean = false;

  constructor(private note: NotificationService,
              private fb: UntypedFormBuilder,
              private userv4: Userv4Service,
              private templatev4: Templatev4Service,
              private templateService: TemplateService) {
    this.allRanges = Ranges.getItems();
  }

  ngOnInit(): void {
    this.selectedRanges = Ranges.toNumbers(this.ranges);

    this.templateForm = this.fb.group({
      name: this.fb.control('', [Validators.required])
    });

    this.getSelf();
  }

  getSelf() {
    this.templatev4.getTemplates().subscribe((templates: Array<ITemplate>) => this.templates = templates)
  }

  get name() {
    return this.templateForm.get('name');
  }

  openTemplateModal() {
    this.showTemplateMenu = false;
    this.showMenu = false;
    this.templateModal.openModal();
  }

  selectRange(range: IRange) {
    if (this.selectedRanges.includes(range.value)) {
      this.selectedRanges = this.selectedRanges.filter(currentRange => currentRange !== range.value);
      this.allRanges = Ranges.getItems();

      this.rangesChange.emit(this.selectedRanges)

      if(this.selectedRanges.length === 0){
        this.selectRange(this.allRanges[1]);

        this.note.notify({
          "title": "Invalid selection",
          "text": "You need to select at least one item from the list. (default to Yesterday)"
        })
      }

      return;
    }

    this.selectedRanges.push(range.value);
    this.rangesChange.emit(this.selectedRanges)
    this.allRanges = Ranges.getItems();
  }

  selectRanges(ranges: Array<number>) {
    this.selectedRanges = ranges;
    this.rangesChange.emit(this.selectedRanges);
    this.allRanges = Ranges.getItems();
  }

  setRanges(ranges: Array<number>) {
    this.selectedRanges = ranges;
    this.allRanges = Ranges.getItems();
  }

  changeRanges(selectedRanges: Array<number>) {
    this.setRanges(selectedRanges);
    this.rangesChange.emit(selectedRanges);
  }

  clickedOutside(){
    this.showMenu = false;
    this.showTemplateMenu = false;
  }

  showHideMenu(){
    this.showMenu = !this.showMenu;
    if(this.showMenu && this.showTemplateMenu){
      this.showTemplateMenu = !this.showTemplateMenu;
    }
  }

  showHideTemplateMenu(){
    this.showTemplateMenu = !this.showTemplateMenu;
    if(this.showMenu && this.showTemplateMenu){
      this.showMenu = !this.showMenu;
    }
  }

  saveTemplate() {
    if (!this.name) {
      return;
    }

    this.templateIsSaving = true;
    this.templateService.createTemplate({
      name: this.name?.value,
      timeRanges: Ranges.toStringArray(this.selectedRanges).map(range => {
        return { summary: range }
      })
    }).subscribe((response: any) => {
      this.templateIsSaving = false;
      this.templateModal.closeModal();
      this.getSelf();
    });
  }

  changeTemplate(template: ITemplate) {
    const ranges = Ranges.toCodeArray(template.ranges.map(result => result.preset));

    this.selectedTemplate = template;
    this.changeRanges(ranges);

    this.showTemplateMenu = false;
  }
}
