import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpStatusCode
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/mavejs/util/notification.service';
import { Authv4Service } from 'src/app/auth/services/authv4.service';

/**
 * @deprecated implement in server error interceptor and avoid retries
 */
@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {

    constructor(private router: Router, private note: NotificationService, private authv4: Authv4Service) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      return next.handle(req).pipe(
          catchError((error: any) => {
              if (req.method === "OPTIONS") {
                return next.handle(req);
              }

              if (error.status === HttpStatusCode.Forbidden && !this.authv4.isExpired()) {
                this.router.navigateByUrl('/errors/403')
        
                return throwError(() => error);
              }        

              if (error.status === HttpStatusCode.Forbidden && this.authv4.isExpired()) {

                this.authv4.logout()
                this.router.navigateByUrl('/user/login')
              }

              return throwError(() => error);
          })
      )
    }
}
