import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[gaConversion]',
  standalone: true
})
export class ConversionDirective implements OnInit {
  @Input() mvolger!: number | undefined;
  @Input() googleconv!: number | undefined;
  @Input() type!: string;

  @HostBinding('class') class = '';

  constructor() { }

  ngOnInit(): void {
    //Conversion in het rood maken als het ene nul is en het andere groter dan twee.

    // Als this.mvolger < Google conv * 0.9
    // Als this.mvolger of Google conv ≥ 10
    // En als this.mvolger/Google conv ≥ 2 of Google/this.mvolger conv ≥ 2
    // Dan kleinste in het rood zetten

    this.mvolger = this.mvolger ?? 0;
    this.googleconv = this.googleconv ?? 0;

    if(this.type === 'mvolger'){
      if(this.mvolger < (this.googleconv * 0.9)){
        this.class = 'text-red';
        return;
      }

      if(this.mvolger === 0 && this.googleconv > 2){
        this.class = 'text-red';
        return;
      }

      if((this.googleconv >= 10) && this.googleconv > this.mvolger){
        if(this.googleconv / this.mvolger >= 2){
          this.class = 'text-red';
          return;
        }
      }
    }

    if(this.type === 'google'){
      if(this.googleconv === 0 && this.mvolger > 2){
        this.class = 'text-red';
        return;
      }

      if((this.mvolger >= 10) && this.mvolger > this.googleconv){
        if(this.mvolger / this.googleconv >= 2){
          this.class = 'text-red';
          return;
        }
      }
    }

    return;
  }
  
}
