import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommentType, IComment, ICommentPreview } from '../classes/comment';
import { Observable } from 'rxjs';
import Url from 'src/app/lib/classes/url';

@Injectable({
  providedIn: 'root'
})
export class Commentv4Service {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @param type 
   * @param entityId 
   * @param text 
   * @returns 
   */
  send(type: CommentType, entityId: number, text: string): Observable<void> {
    let url = new Url("/comments/send")

    return this.http.post<void>(url.build(), { type: type, entity: entityId, text: text })
  }

  /**
   * 
   * @param type 
   * @param entityId 
   * @returns 
   */
  load(type: CommentType, entityId: number): Observable<Array<IComment>> {
    let type_url = type === CommentType.ACCOUNT ? "/by_account" : "/by_campaign";
    let url = new Url("/comments/" + entityId + type_url);

    return this.http.get<Array<IComment>>(url.build());
  }

  /**
   * 
   * @param commentId 
   */
  delete(commentId: number) {
    let url = new Url("/comments/" + commentId + "/delete");

    return this.http.delete<void>(url.build());
  }

  /**
   * 
   */
  previewsByAccount(accountId: number): Observable<ICommentPreview[]> {
    let url = new Url("/comments/" + accountId + "/by_account/previews")

    return this.http.get<ICommentPreview[]>(url.build());
  }


  /**
 * 
 */
  previews(): Observable<ICommentPreview[]> {
    let url = new Url("/comments/previews")

    return this.http.get<ICommentPreview[]>(url.build());
  }


}
