<div class="flex justify-center items-center h-screen -mt-32">

  <div>
    <div class="text-center">
      <i class="material-symbols-outlined text-primary text-5xl">directions</i>
    </div>

    <div class="text-center mt-1">
      <div class="ga-not-found__title">Page not found</div>
    </div>

    <div class="text-center mt-2">
      <mave-button (press)="back()" variant="secondary" label="Go Back"></mave-button>
    </div>
  </div>

</div>
