import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Domain } from '../interfaces/domain';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(private http: HttpClient) { }


  public findById(id: number, accountId: number): Observable<Domain> {
    return this.http.get<Domain>(environment.baseUrl + "/api/v1/domain/" + id + "?tenant=" + accountId);
  }

  public renew(id: number, accountId: number) {
    return this.http.post(environment.baseUrl + "/api/v1/domain/" + id + "/renew?tenant=" + accountId, {});
  }

}
