<mave-notification-area [keepAlive]="3000"></mave-notification-area>

<p-confirmDialog #adBlocker header="Adblocker detected" icon="pi pi-exclamation-triangle" key="adBlocker" [rejectVisible]="false" [closable]="false">
  <ng-template pTemplate="headless">
    <div class="mv-confirm">
      <div body>
        <div class="flex flex-col items-center p-12">
          <mv-title variant="positive" [size]="2">
            Adblocker detected
          </mv-title>
          <div class="material-symbols-outlined mv-confirm-icon">warning</div>
          <div>Please turn it off to continue using this application</div>
        </div>
      </div>
      <mv-button type="button" icon="check" label="Done" (click)="adBlocker.accept()"></mv-button>
    </div>
  </ng-template>
</p-confirmDialog>
<!-- template for all confirmation dialogs, access with confirmation service -->
<p-confirmDialog #cd key="delete">
  <ng-template pTemplate="headless" let-message>
    <div class="mv-confirm" style="min-width: 24rem;">
      <div body>
        <div class="flex flex-col items-center p-12">
          <mv-title [variant]="message.icon === 'delete' ? 'danger' : 'primary'" [size]="2">
            {{ message.header }}
          </mv-title>
          <div class="material-symbols-outlined mv-confirm-icon" [ngClass]="{'mv-confirm-icon-danger': (message.icon === 'delete')}">{{ message.icon }}</div>
          {{ message.message }}
        </div>
        <div class="flex justify-between">
          <mv-button [variant]="message.icon === 'delete' ? 'danger' : 'primary'" label="Confirm" (click)="cd.accept()"></mv-button>
          <mv-button variant="secondary" label="Cancel" (click)="cd.reject()"></mv-button>
        </div>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
<!-- template for positive dialogs -->
<p-confirmDialog #pcd key="positive">
  <ng-template pTemplate="headless" let-message>
    <div class="mv-confirm" style="min-width: 24rem;">
      <div body>
        <div class="flex flex-col items-center p-12">
          <mv-title variant="positive" [size]="2">
            {{ message.header }}
          </mv-title>
          <div class="material-symbols-outlined mv-confirm-icon mv-confirm-icon-positive">{{ message.icon }}</div>
          {{ message.message }}
        </div>
        <div class="flex justify-between">
          <mv-button variant="positive" label="Confirm" (click)="pcd.accept()"></mv-button>
          <mv-button variant="secondary" label="Cancel" (click)="pcd.reject()"></mv-button>
        </div>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>

<div class="ga-navbar-fixed-wrapper">
  <div class="ga-alert" [class.ga-alert-activated]="hasAlert"><span class="material-symbols-outlined mr-3">warning</span> {{ alert.message }}</div>
  <div class="ga-alert" [class.ga-alert-activated]="isTesting">Testing environment</div>
  <ganal-navbar [pageTitle]="pageTitle" *ngIf="!hideNavBar"></ganal-navbar>
</div>

<div
[class.ga-padding-navbar-only]="!hideNavBar && !hasAlert && !isTesting"
[class.ga-padding-navbar-and-one-alert]="!hideNavBar && (hasAlert || isTesting)"
[class.ga-padding-navbar-and-two-alerts]="!hideNavBar && (hasAlert && isTesting)"
class="w-full p-2 lg:p-10">
    <router-outlet (activate)="getPageTitle()"></router-outlet>
</div>
<ganal-version *ngIf="!hideNavBar"></ganal-version>