import { Account, IAccount } from "src/app/account/interfaces/account";
import { AccountCpmValueParser, AdCtrValueParser, AdjustedRoiGraphValueParser, CVRValueParser, GraphValueParser, LanderCtrValueParser } from "../graph-value-parser";

export interface IGraphFormatter {

  format(): string;
  min(): number;
  max(): number;

}

export class AdjustedGraphRoiFormatterBuilder implements IGraphFormatter {

  private value!: any;
  private parser!: AdjustedRoiGraphValueParser;

  constructor(value: any, parser: AdjustedRoiGraphValueParser) {
    this.value = value;
    this.parser = parser;
  }

  public format(): string {
    return Math.round(this.parser.reverse(this.value)) + '%';
  }

  max(): number {
      return 100;
  }

  min(): number {
      return 0;
  }

}

export class AdjustedCPMFormatter implements IGraphFormatter {
  
  private value!: any;
  private parser!: AccountCpmValueParser;
  private account: IAccount;

  constructor(value: any, parser: AccountCpmValueParser, account: IAccount) {
    this.value = value;
    this.parser = parser;
    this.account = account;
  }

  public format(): string {
    return Math.round(this.parser.reverse(this.value)) + Account.getCurrencyCode(this.account);
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }

}

export class AdjustedAdCtrFormatter implements IGraphFormatter {
  private value!: any;
  private parser!: AdCtrValueParser;

  constructor(value: any, parser: AdCtrValueParser) {
    this.value = value;
    this.parser = parser;
  }

  public format(): string {
    return Math.round(this.parser.reverse(this.value)) + '%';
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }

}

export class AdjustedLanderCTRFormatter implements IGraphFormatter {
  private value!: any;
  private parser!: LanderCtrValueParser;

  constructor(value: any, parser: LanderCtrValueParser) {
    this.value = value;
    this.parser = parser;
  }

  public format(): string {
    return Math.round(this.parser.reverse(this.value)) + '%';
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }
}

export class AdjustedCVRFormatter implements IGraphFormatter {
  private value!: any;
  private parser!: CVRValueParser;

  constructor(value: any, parser: CVRValueParser) {
    this.value = value;
    this.parser = parser;
  }

  public format(): string {
    return Math.round(this.parser.reverse(this.value)) + '%';
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }
}


