import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[gaYtGrading]',
  standalone: true
})
export class YtGradingDirective implements OnInit {
  @Input() percentage!: number;

  @HostBinding('class') class = '';
  
  constructor() { }

  ngOnInit(): void {
    this.class = this.grade();
  }  

  grade(): string {
    const grades: any = {
      'GOOD': ['text-gray'],
      'MEDIUM': ['text-yellow'],
      'DECLINING': ['text-orange-light'],
      'BAD': ['text-red']
    };

    if(this.percentage > 0.30){
      return grades['BAD'] !== undefined ? grades['BAD'] : grades['none']
    }else if (this.percentage <= 0.30 && this.percentage > 0.10) {
      return grades['DECLINING'] !== undefined ? grades['DECLINING'] : grades['none']
    }else{
      return grades['GOOD'] !== undefined ? grades['GOOD'] : grades['none']
    }
  }
}
