import { Component, Input, OnInit } from '@angular/core';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { Adv4Service } from '../../services/adv4.service';
import { DateRange } from 'src/app/lib/classes/daterange';
import { IAdTotals } from '../../classes/ad/ad';
import { CurrencyPipe, NgFor, NgIf, PercentPipe } from '@angular/common';
import { IAccount } from 'src/app/account/interfaces/account';
import { RangeLabelPipe } from '../../pipes/range-label.pipe';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';

@Component({
  selector: 'ga-ad-totals',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CardComponent,
    PercentPipe,
    CurrencyPipe,
    RangeLabelPipe,
    TitleComponent
  ],
  templateUrl: './ad-totals.component.html',
  styleUrl: './ad-totals.component.css'
})
export class AdTotalsComponent implements OnInit {
  @Input() campaignId!: number;
  @Input() account!: IAccount;
  @Input() ranges!: Array<DateRange>;

  rangeTotals: Map<String, IAdTotals> = new Map()

  constructor(private adv4: Adv4Service) {}

  ngOnInit(): void {
    this.load(this.ranges)
  }

  load(ranges: Array<DateRange>) {
    for (let range of ranges) {
      this.adv4.getAdTotals(this.campaignId, range).subscribe((totals: IAdTotals) => {
        this.rangeTotals.set(range.toString(), totals);
      })
    }
  }

}
