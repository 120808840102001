import { Pipe, PipeTransform } from '@angular/core';
import { OperationType } from '../../classes/operation-type';

@Pipe({
  standalone: true,
  name: 'operationIsValue'
})
export class OperationIsValuePipe implements PipeTransform {

  transform(value: string): unknown {
    return new OperationType(value).isValue();
  }

}
