import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdService} from "../../../ad/services/ad.service";
import {ICampaignChange} from "../../interfaces/campaign-change";
import {IAccount} from "../../../account/interfaces/account";
import {ICampaignMetricsResult} from "../../interfaces/campaign-metrics-result";
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { DatePipe, JsonPipe, NgClass, NgFor, NgIf } from '@angular/common';

import { LoaderComponent } from 'src/app/mavejs/http/loader/loader.component';
import { StatusTypePipe } from 'src/app/status/pipes/legacy/status-type.pipe';
import { OperationIsValuePipe } from 'src/app/status/pipes/legacy/operation-is-value.pipe';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { CardComponent as LegacyCardComponent } from 'src/app/mavejs/base/card/card.component';
import { TableModule } from 'primeng/table';
import { featureIsActivated } from 'src/app/lib/classes/feature';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { IStatus } from 'src/app/status/interfaces/status-change';
import { DatatableComponent } from 'src/app/mavejs/data/datatable/datatable.component';
import { FeatureComponent } from 'src/app/feature/feature.component';
import { OperationPipe } from 'src/app/status/pipes/operation.pipe';
import { TypePipe } from 'src/app/status/pipes/type.pipe';
import { StatusValuePipe } from 'src/app/status/pipes/status-value.pipe';
import { TypeLabelPipe } from '../../pipes/type-label.pipe';

@Component({
  selector: 'ga-change-history',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    TooltipModule,
    RouterLink,
    TableModule,
    CardComponent,
    LegacyCardComponent,
    LoaderComponent,
    DatePipe,
    StatusTypePipe,
    OperationIsValuePipe,
    DatatableComponent,
    FeatureComponent,
    OperationPipe,
    TypePipe,
    StatusValuePipe,
    TooltipModule,
    TypeLabelPipe,
    JsonPipe
  ],
  templateUrl: './change-history.component.html',
  styleUrls: ['./change-history.component.scss']
})
export class ChangeHistoryComponent implements OnInit {
  @Input() changeHistoryType!: string;
  @Input() accountId!: number;
  @Input() campaignId!: number;

  @Output() count: EventEmitter<number> = new EventEmitter<number>();
  @Output() highlight = new EventEmitter();

  changes!: ICampaignChange[];
  v4changes!: IStatus[];
  today = new Date();
  isLoadingChanges: boolean = true;

  statusHeaders = [
    {value: 'date', label: 'Date', placeRight: false, sortable: false},
    {value: 'user', label: 'User', placeRight: false, sortable: false},
    {value: 'type', label: 'Type', placeRight: false, sortable: false},
    {value: 'operation', label: 'Operation', placeRight: false, sortable: false},
    {value: 'value', label: 'Value', placeRight: false, sortable: false}
  ];

  constructor(private adService: AdService, private statusv4: Statusv4Service) { }

  ngOnInit(): void {
    this.getChanges();
  }

  getChanges() {
    this.statusv4.history(this.accountId).subscribe((changes: IStatus[]) => {
      this.v4changes = changes
    })
  }

  buildCampaignLink(accountId: number, adgroupId: number) {
    return '/ad/' + accountId + '/account/' + adgroupId + '/adgroup'
  }

  /**
   * 
   * @param first 
   * @param second 
   * @returns 
   */
  sameDay(first: Date, second: Date): boolean {
    return new Date(first).getDate() === second.getDate()
  }

  /**
   * 
   * @param campaignId 
   */
  highlightCampaign(campaignId: number) {
    this.highlight.emit(campaignId);
  }

  /**
   * 
   */
  unhighlight() {
    this.highlight.emit(0);
  }
}
