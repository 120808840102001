import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user/services/user.service';
import { User } from 'src/app/template/classes/template';
import { Authv4Service } from 'src/app/auth/services/authv4.service';
import { MenuSplitComponent } from '../../../mavejs/base/menu-split/menu-split.component';
import { MenuItemComponent } from '../../../mavejs/base/menu-item/menu-item.component';
import { MenuComponent } from '../../../mavejs/base/menu/menu.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ganal-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    standalone: true,
    imports: [NgIf, MenuComponent, MenuItemComponent, MenuSplitComponent]
})
export class UserMenuComponent implements OnInit {
  user!: User
  isMenuOpen = false;

  constructor(private router: Router, private userService: UserService, private authv4: Authv4Service) { }

  ngOnInit(): void {
    this.userService.getSelf().subscribe((user: User) => {
      this.user = user;
    })
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  logout() {
    // this.userService.logout().subscribe(() => {
    //   this.router.navigateByUrl("/user/login");
    //   this.auth.clearSessionToken();
    // });

    this.authv4.logout()
    this.router.navigateByUrl("/user/login")
  }

}
