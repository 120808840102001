import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAdGroup } from '../interfaces/adgroup';
import Url from 'src/app/lib/classes/url';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Adgroupv4Service {

  constructor(private http: HttpClient) { }

  public getAdGroupFromCampaignId(campaignId: number): Observable<IAdGroup> {
    let url = new Url("/adgroup/" + campaignId)

    return this.http.get<IAdGroup>(url.build());
  }
}
