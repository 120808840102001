import { environment } from "../../../environments/environment";


export interface IQuery {
    param: string;
    value: string;
}

export default class Url {

    private endpoint: string;
    private params: string;

    constructor (endpoint: string) {
        this.endpoint = endpoint;
        this.params = ""
    }

    query(key: string, value: any) {
        if (value === undefined || value === '') {
            return this;
        }
        
        if (this.params === "" && value !== null) {
            this.params = this.params + '?' + key + "=" + value;
        } else {
            if (value !== null) {
                this.params = this.params + '&' + key + "=" + value;
            }
        }

        return this;
    }

    build() {
        return environment.apiv4 + this.endpoint + this.params;
    }

}

/**
 * 
 * @param queryString 
 * @returns 
 */
export function queryParse(queryString: string): Array<IQuery> {
    let queries: Array<IQuery> = []

    if(typeof queryString !== 'string') {
        return [];
    }
     
   // map the hit query into a proper object
   queryString.replace(/([^&|\?=]+)=?([^&]*)(?:&+|$)/g, (match, key, value) => {
        queries.push({
            param: key,
            value: value
        })

        return ''
   });

   return queries;
}