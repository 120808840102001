import { AfterViewInit, Directive, HostBinding, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[gaGrade]',
  standalone: true
})
export class GradeDirective implements OnChanges {
  @Input() roi!: number | null;
  @Input() conversions: number = 0;

  @HostBinding('class') classes = 'ga-grade ';

  constructor() { }

  ngOnChanges(): void {
    const grades: any = {
      'GOOD': ['bg-green-light', 'text-green-dark'],
      'MEDIUM': ['bg-yellow-lighter', 'text-yellow-dark'],
      'DECLINING': ['bg-orange-light', 'text-orange-dark'],
      'BAD': ['bg-red-light', 'text-red-dark'],
      'NEUTRAL': ['bg-gray-light'],
      'NONE': []
    };
    let type = this.determineGrade();

    this.classes = 'ga-grade ';

    for (let className of grades[type]) {
      this.classes += className + ' ';
    }
  }

  determineGrade(): string {
    if (this.roi === null || isNaN(this.roi) || this.roi === undefined) {
      return 'NONE'
    }

    if (this.roi > 0.4) {
        return 'GOOD';
    }

    if (this.roi > 0.1) {
        return 'MEDIUM';
    }

    if (this.roi > -0.1) {
        return 'DECLINING';
    }

    return 'BAD'
  }

}
